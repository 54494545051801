import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

const ProtectedRoute = ({component:Component, signedIn, signedInChecked }) => {
    return (<Route render={props => {
        if(signedIn){
            return <Component {...props} />
        } else if(signedInChecked){
            return <Redirect to="/" />
        } else {
            return ''
        }        
    }}/>
    )
}
const mapStateToProps = state =>{
    return {
        signedIn:state.auth.signedIn,
        signedInChecked:state.auth.signedInChecked,
    };
};

export default connect(mapStateToProps)(ProtectedRoute);