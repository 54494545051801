import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReporteBody from '../../components/ReporteBody'
import { useHistory } from 'react-router-dom'
import {
  fetchWorkersWithoutMonthlyPaymentDocument
} from '../../store/actions/reportsActions'

const ReportTrabajadoresSinNingunaLiquidacion = ({
  fetchWorkersWithoutMonthlyPaymentDocument
}) => {
  
  const history = useHistory()

  const fetchReportData = ({
    Empresas,
    CentrosCosto,
    FechaIngreso,
    FechaIngreso2,
    Regiones,
    Sindicatos,
    Rut,
    Nombre,
    Estados,
    PageSize,
    PageNumber,
    OnFetchComplete,
  }) => {
    fetchWorkersWithoutMonthlyPaymentDocument({
      Empresas,
      CentrosCosto,
      FechaIngreso,
      FechaIngreso2,
      Regiones,
      Sindicatos,
      Rut,
      Nombre,
      Estados,
      PageSize,
      PageNumber,
      history,
      OnFetchComplete,
    })
  }

  return (
    <ReporteBody
      Report="WorkersWithoutMonthlyPaymentDocument"
      ReportTitle="Trabajadores sin Ninguna Liquidacion"
      fetchReportData={fetchReportData}
      filtersToHide={["Estado"]}
    />
  )
}

const mapStateToProps = state => {
  return { 
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchWorkersWithoutMonthlyPaymentDocument
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ReportTrabajadoresSinNingunaLiquidacion)