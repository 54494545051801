import React, { useEffect/*, useState, useCallback*/ } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
import NonRutProtectedRoute from './components/NonRutProtectedRoute'
import SignInCheck from './screens/SignInCheck'
import Login from './screens/Login'
import ResetPassword from './screens/ResetPassword'
import AdministracionDePersonal from './screens/AdministracionDePersonal'
import ExportarPersonal from './screens/ExportarPersonal'
import DetallesPersonalLiquidaciones from './screens/DetallesPersonal/Liquidaciones'
import DetallesPersonalDocumentos from './screens/DetallesPersonal/Documentos'
import UploadDocumentos from './screens/Upload/Documentos'
import UploadLiquidaciones from './screens/Upload/Liquidaciones'
import PanelConfiguracion from './screens/Configuracion/PanelConfiguracion'
import MantenedorDocumentos from './screens/Configuracion/MantenedorDocumentos'
import MantenedorUsuarios from './screens/Configuracion/MantenedorUsuarios'
import PanelReportes from './screens/Reportes/PanelReportes'
import ReportActivosSinContrato from './screens/Reportes/ReportActivosSinContrato'
import ReportContratosTerminadosSinFiniquito from './screens/Reportes/ReportContratosTerminadosSinFiniquito'
import ReportTrabajadoresSinNingunDocumento from './screens/Reportes/ReportTrabajadoresSinNingunDocumento'
import ReportTrabajadoresMenos6Liquidaciones from './screens/Reportes/ReportTrabajadoresMenos6Liquidaciones'
import ReportTrabajadoresSinUltimaLiquidacion from './screens/Reportes/ReportTrabajadoresSinUltimaLiquidacion'
import ReportTrabajadoresSinNingunaLiquidacion from './screens/Reportes/ReportTrabajadoresSinNingunaLiquidacion'
import ReportTrabajadoresSinVacaciones from './screens/Reportes/ReportTrabajadoresSinVacaciones'
import ExportarReporte from './screens/Reportes/ExportarReporte'
import ReportesLiquidaciones from './screens/Reportes/ReportesLiquidaciones'
import ReportesLiquidacionesResultados from './screens/Reportes/ReportesLiquidacionesResultados'
import ReportesDocumentos from './screens/Reportes/ReportesDocumentos'
import ReportesDocumentosResultados from './screens/Reportes/ReportesDocumentosResultados'
import ExportarReporteDocumentosPersonalizado from './screens/Reportes/ExportarReporteDocumentosPersonalizado'
import ExportarReporteLiquidacionesPersonalizado from './screens/Reportes/ExportarReporteLiquidacionesPersonalizado'

import { 
  setSignedInChecked,
  setAuthData
} from './store/actions/authActions'
import {
  fetchCompanies,
  fetchCostCenters,
  fetchRegions,
  fetchDocuments,
  fetchSindicatos,
} from './store/actions/mantenedoresActions'

const App = ({
  authUserName,
  setSignedInChecked,
  setAuthData,
  signedInChecked,
  fetchCostCenters,
  fetchCompanies,
  fetchRegions,
  fetchDocuments,
  fetchSindicatos
}) => {

  useEffect(()=>{
    if(authUserName!==''){
      fetchCompanies()
      fetchCostCenters()
      fetchRegions()
      fetchDocuments()
      fetchSindicatos()
    }
  },[fetchCostCenters,fetchCompanies,fetchRegions,fetchDocuments,fetchSindicatos,authUserName])

  useEffect(()=>{
    if(!signedInChecked){
      setTimeout(()=>{
        let data = localStorage.getItem('docuus')
        if(data!==null){
          let { UserName, token, userType,nombre,apellido,canUploadDocuments } = JSON.parse(data)
          setAuthData({ UserName, token, userType,nombre, apellido,canUploadDocuments })
        } else {
          setSignedInChecked({})
        }
      },1000)
    }
  },[signedInChecked,setSignedInChecked,setAuthData])

  if(!signedInChecked)
    return <SignInCheck/>

  return (
  <BrowserRouter>
    <Switch>
      {/* Public pages */}
      <Route path='/' component={Login} exact/>
      <Route path='/login' component={Login} exact/>
      <Route path='/resetpassword' component={ResetPassword} exact/>
      <NonRutProtectedRoute path='/dashboard' component={AdministracionDePersonal} exact/>
      <ProtectedRoute path='/detallesPersonal/liquidaciones' component={DetallesPersonalLiquidaciones} exact/>
      <ProtectedRoute path='/detallesPersonal/documentos' component={DetallesPersonalDocumentos} exact/>
      <NonRutProtectedRoute path='/upload/documentos' component={UploadDocumentos} exact/>
      <NonRutProtectedRoute path='/upload/liquidaciones' component={UploadLiquidaciones} exact/>
      <NonRutProtectedRoute path='/configuracion/panel' component={PanelConfiguracion} exact/>
      <NonRutProtectedRoute path='/configuracion/documentos' component={MantenedorDocumentos} exact/>
      <NonRutProtectedRoute path='/configuracion/usuarios' component={MantenedorUsuarios} exact/>
      <NonRutProtectedRoute path='/reports/panel' component={PanelReportes} exact/>
      <NonRutProtectedRoute path='/export' component={ExportarPersonal} exact/>
      <NonRutProtectedRoute path='/reports/trabajadores-activos-sin-contrato' component={ReportActivosSinContrato} exact/>
      <NonRutProtectedRoute path='/reports/contratos-terminados-sin-finiquito' component={ReportContratosTerminadosSinFiniquito} exact/>
      <NonRutProtectedRoute path='/reports/trabajadores-sin-documentos' component={ReportTrabajadoresSinNingunDocumento} exact/>
      <NonRutProtectedRoute path='/reports/trabajadores-menos-6-liquidaciones' component={ReportTrabajadoresMenos6Liquidaciones} exact/>
      <NonRutProtectedRoute path='/reports/trabajadores-sin-ultima-liquidacion' component={ReportTrabajadoresSinUltimaLiquidacion} exact />
      <NonRutProtectedRoute path='/reports/trabajadores-sin-ninguna-liquidacion' component={ReportTrabajadoresSinNingunaLiquidacion} exact />
      <NonRutProtectedRoute path='/reports/trabajadores-sin-vacaciones' component={ReportTrabajadoresSinVacaciones} exact />
      <NonRutProtectedRoute path='/reports/export' component={ExportarReporte} exact/>
      <NonRutProtectedRoute path='/reports/liquidaciones' component={ReportesLiquidaciones} exact/>
      <NonRutProtectedRoute path='/reports/liquidaciones/result' component={ReportesLiquidacionesResultados} exact/>
      <NonRutProtectedRoute path='/reports/liquidaciones/export' component={ExportarReporteLiquidacionesPersonalizado} exact/>
      <NonRutProtectedRoute path='/reports/documentos' component={ReportesDocumentos} exact/>
      <NonRutProtectedRoute path='/reports/documentos/result' component={ReportesDocumentosResultados} exact/>
      <NonRutProtectedRoute path='/reports/documentos/export' component={ExportarReporteDocumentosPersonalizado} exact/>
    </Switch>
  </BrowserRouter>
  )
}

const mapStateToProps = state => {
  return {
    signedInChecked:state.auth.signedInChecked,
    authUserName:state.auth.UserName,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCostCenters,
  fetchCompanies,
  fetchRegions,
  fetchDocuments,
  fetchSindicatos,
  setSignedInChecked,
  setAuthData,
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(App)
