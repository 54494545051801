import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Container, Row, Col, Button } from 'react-bootstrap'
import styled from "styled-components";
import Modal from 'react-overlays/Modal'
import moment from 'moment'
import { fileNameValidationRegexp } from '../regexp'

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const DeleteAttachmentModal = styled(Modal)`
  position: fixed;
  top: 10%;
  left: 25%;
  right: 25%;
  height:280px;
  z-index: 1040;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

const DeleteAttachmentConfirmation = ({
  details,
  costCenters,
  documents,
  upload,
  HideModal,
  OnDelete
})=>
{
  const renderBackdrop = (props) => <Backdrop {...props} />;
  let parts = new RegExp(fileNameValidationRegexp).exec(upload.s3Url);
  let datePart = moment(parts[4],"YYYYMMDD")

  return (
    <DeleteAttachmentModal
      show={true}
      onHide={HideModal}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <Container style={{paddingLeft:'6rem',paddingRight:'6rem'}}>
        <h4 id="modal-label">¿Esta seguro que desea eliminar el documento?</h4>
        <Row>
          <Col xs={4}><strong>Tipo de documento:</strong></Col>
          <Col style={{justifyContent:'flex-start'}}>{documents.find(doc=>doc.Codigo === upload.Codigo)?.Descripcion}</Col>
        </Row>
        <Row>
          <Col xs={4}><strong>Empresa:</strong></Col>
          <Col style={{justifyContent:'flex-start'}}>{upload.rexUser}</Col>
        </Row>
        <Row>
          <Col xs={4}><strong>Centro de Costo:</strong></Col>
          <Col>{costCenters.find(coc=>coc.item === (''+upload.centroCosto))?.nombre}</Col>
        </Row>
        <Row>
          <Col xs={4}><strong>Nombre Empleado:</strong></Col>
          <Col>{details.nombre} {details.apellidoPate} {details.apellidoMate}</Col>
        </Row>
        <Row>
          <Col xs={4}><strong>Rut Empleado:</strong></Col>
          <Col>{upload.empleado}</Col>
        </Row>
        <Row>
          <Col xs={4}><strong>Fecha Documento:</strong></Col>
          <Col>{["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"][datePart.month()]} {datePart.year()}</Col>
        </Row>
        <Row style={{marginTop:'1rem', justifyContent:'space-evenly'}}>
          <Button variant="outline-secondary" onClick={HideModal}>Cancelar</Button>
          <Button variant="outline-secondary" onClick={OnDelete} active>Eliminar</Button>
        </Row>
      </Container>
    </DeleteAttachmentModal>
  )
}

const mapStateToProps = state => {
  return {
    details:state.userDetails.details,
    costCenters:state.costCenters.costCenters,
    documents:state.documents.documents
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAttachmentConfirmation)