import { 
  SET_SIGNEDIN_CHECKED,
  SET_AUTH_TOKEN,
  SET_CLEAN_AUTH,
  SET_RESET_PASSWORD,
} from '../actions/actionTypes'

const initialState = {
  UserName:'',
  userType:'',
  nombre:'',
  apellido:'',
  canUploadDocuments:false,
  token:'',
  signedIn:false,
  signedInChecked:false,
  resetPassword:false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLEAN_AUTH:
      return {...initialState}
    case SET_SIGNEDIN_CHECKED:
      return {...state,signedInChecked:true}
    case SET_RESET_PASSWORD:
      return {...state, resetPassword:true, UserName:action.UserName}
    case SET_AUTH_TOKEN:
      return {
        UserName:action.UserName,
        userType:action.userType,
        nombre:action.nombre,
        apellido:action.apellido,
        canUploadDocuments:action.canUploadDocuments,
        token:action.token,
        signedIn:true,
        signedInChecked:true,
        resetPassword:false,
      };
    default:
      return state;
  }
} 

export default reducer