import { 
  SET_CONFIGURACION_USUARIOS
} from '../actions/actionTypes'

const initialState = {
  usuarios:[],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIGURACION_USUARIOS:
      return {
        usuarios: [...action.usuarios]
      };
    default:
      return state;
  }
} 

export default reducer