import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const SignInCheck = () => {
  return (
    <Container>
      <Col style={{marginTop:'30%'}}>
        <Row className="justify-content-sm-center">
          <FontAwesomeIcon icon={faSpinner} spin={true}/>
        </Row>
        <Row className="justify-content-sm-center">
          <h4>Cargando</h4>
        </Row>
      </Col>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SignInCheck)