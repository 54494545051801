import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { 
  fetchReportsCustomDocumentsExportUrl
} from '../../store/actions/reportsActions'

const ExportarReporteDocumentosPersonalizado = ({
  fetchReportsCustomDocumentsExportUrl
}) =>{

  let query = new URLSearchParams(useLocation().search)
  
  const [Empresas] = useState(query.get("Empresas"))
  const [CentrosCosto] = useState(query.get("CentrosCosto"))
  const [FechaIngreso] = useState(query.get("FechaIngreso"))
  const [FechaIngreso2] = useState(query.get("FechaIngreso2"))
  const [FechaDocumento] = useState(query.get("FechaDocumento"))
  const [FechaDocumento2] = useState(query.get("FechaDocumento2"))
  const [Regiones] = useState(query.get("Regiones"))
  const [Sindicatos] = useState(query.get("Sindicatos"))
  const [Rut] = useState(query.get("Rut"))
  const [Nombre] = useState(query.get("Nombre"))
  const [Estados] = useState(query.get("Estados"))
  const [Condicion] = useState(query.get("Condicion"))
  const [Documentos] = useState(query.get("Documentos"))

  useEffect(()=>{
    fetchReportsCustomDocumentsExportUrl({
      Empresas,
      CentrosCosto,
      FechaIngreso,
      FechaIngreso2,
      FechaDocumento,
      FechaDocumento2,
      Regiones,
      Sindicatos,
      Rut,
      Nombre,
      Estados,
      Condicion,
      Documentos
    })
  },[Empresas,CentrosCosto,FechaIngreso,FechaIngreso2,FechaDocumento,FechaDocumento2,Regiones,Sindicatos,Rut,Nombre,Estados,Condicion,Documentos,fetchReportsCustomDocumentsExportUrl])

  return (
    <div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchReportsCustomDocumentsExportUrl
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ExportarReporteDocumentosPersonalizado)