import React, { useState, useEffect, useCallback } from 'react';
import { Container, Col, Row, Nav, ProgressBar } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faCheckCircle, faTimesCircle, faCircleNotch, faInbox } from '@fortawesome/free-solid-svg-icons';
import {
  onUploadBegin,
  getNewUploadBatchId
  } from '../utils/S3Upload';
import { fileNameValidationRegexp } from '../regexp'

export const UploadStageUploadFiles = ({
  documents,
  filesToUpload,
  company,
  costCenters,
  costCenter,
  Contrato,
  ProgressValue,
  ProgressText,
}) => {
  const location = useLocation();
  const [filesUploadInQueue, setFilesUploadInQueue] = useState(new Set())
  const [filesUploadResults, setFilesUploadResults] = useState(new Map())
  const [filesUploadUser, setFilesUploadUser] = useState(new Map())
  const [doUpload, setDoUpload] = useState(true);
  const [filesUploadCostCenters, setFilesUploadCostCenters] = useState(new Map())

  const addFileToQueue = useCallback(fileName => {
    setFilesUploadInQueue(prevFiles => {
      let newSet = new Set(prevFiles)
      newSet.add(fileName);
      return newSet;
    });
  },[setFilesUploadInQueue]);
  const addUserToFile = useCallback((fileName, user) => {
    setFilesUploadUser(prevUploadUsers => {
      let newMap = new Map(prevUploadUsers);
      newMap.set(fileName, user)
      return newMap;      
    })
  },[setFilesUploadUser])
  const addCostCenterToFile = useCallback((fileName, cocItem) => {
    setFilesUploadCostCenters(prevUploadCostCenters => {
      let newMap = new Map(prevUploadCostCenters);
      newMap.set(fileName, cocItem)
      return newMap;      
    })
  },[setFilesUploadCostCenters])

  const addFileToUploadResult = useCallback((fileName, result) => {
    setFilesUploadResults(prevUploadResults => {
      let newMap = new Map(prevUploadResults);
      newMap.set(fileName, result)
      return newMap;
    });
  },[setFilesUploadResults]);

  useEffect(() => {
    const uploadDocuments = async (batchId) => {
      for(let i = 0 ; i < filesToUpload.length; i++){
        let file = filesToUpload[i]
          await onUploadBegin({
            batchId,
            file,
            company,
            costCenter,
            Contrato,
            addToUploadQueue:addFileToQueue,
            addUserToFile:addUserToFile,
            addCostCenterToFile:addCostCenterToFile,
            addToResultQueue:addFileToUploadResult
        });
      }
    };
    const getUploadBatchIdAndUpload = async () => {
      let batchId = await getNewUploadBatchId()
      uploadDocuments(batchId)
    }
    if (doUpload) {
      setDoUpload(false);
      getUploadBatchIdAndUpload()
    }
  }, [
    filesToUpload,
    company,
    costCenter,
    Contrato,
    addFileToQueue,
    addUserToFile,
    addCostCenterToFile,
    addFileToUploadResult,
    doUpload, setDoUpload
  ]);

  const toUploadRow = (file, index) => {
    let parts = new RegExp(fileNameValidationRegexp).exec(file.name);
    let userData = filesUploadUser.get(file.name)
    return (
      <li key={index}>
        <Row>
          <Col>
            <FontAwesomeIcon icon={faFilePdf} />
          </Col>
          <Col>
            {company}
          </Col>
          {costCenter !== undefined &&
          <Col title={costCenters.find(coc => coc.item === costCenter)?.nombre || ''}>
            {costCenters.find(coc => coc.item === costCenter)?.nombre || ''}
          </Col>
          }
          {costCenter === undefined &&
          <Col title={costCenters.find(coc => coc.item === filesUploadCostCenters.get(file.name))?.nombre || ''}>
            {costCenters.find(coc => coc.item === filesUploadCostCenters.get(file.name))?.nombre || ''}
          </Col>
          }
          <Col>
            {parts[2]}-{parts[3]}
          </Col>
          <Col title={[userData?.nombre||'',userData?.apellidoPate||'',userData?.apellidoMate||''].join(' ')}>
            {[userData?.nombre||'',userData?.apellidoPate||'',userData?.apellidoMate||''].join(' ')}
          </Col>
          <Col>
            {parts[1]}
          </Col>
          <Col title={documents.find(doc => doc.Codigo === parts[1]).Descripcion}>
            {documents.find(doc => doc.Codigo === parts[1]).Descripcion}
          </Col>
          {!filesUploadInQueue.has(file.name) && !filesUploadResults.has(file.name) &&
          <Col title={`Encolando`}>
            Encolando
          </Col>
          }
          {filesUploadInQueue.has(file.name) && !filesUploadResults.has(file.name) &&
          <Col title={`Cargando`}>
            Cargando
          </Col>
          }
          {filesUploadInQueue.has(file.name) && filesUploadResults.has(file.name) && 
          <Col title={filesUploadResults.get(file.name).message}>
            {filesUploadResults.get(file.name).message}
          </Col>
          }
          <Col>
            {!filesUploadInQueue.has(file.name) && !filesUploadResults.has(file.name) && <FontAwesomeIcon icon={faInbox} />}
            {filesUploadInQueue.has(file.name) && !filesUploadResults.has(file.name) && <FontAwesomeIcon icon={faCircleNotch} spin />}
            {filesUploadInQueue.has(file.name) && filesUploadResults.has(file.name) && filesUploadResults.get(file.name).status === 'success' && <FontAwesomeIcon icon={faCheckCircle} color="green" />}
            {filesUploadInQueue.has(file.name) && filesUploadResults.has(file.name) && filesUploadResults.get(file.name).status !== 'success' && <FontAwesomeIcon icon={faTimesCircle} color="red" />}
          </Col>
        </Row>
      </li>
    );
  };

  const totalFiles = filesToUpload.map(toUploadRow);
  const successfulFiles = filesToUpload.filter(file => {
    return (filesUploadResults.has(file.name) && filesUploadResults.get(file.name).status === 'success');
  }).map(toUploadRow);
  const failedFiles = filesToUpload.filter(file => {
    return (filesUploadResults.has(file.name) && filesUploadResults.get(file.name).status !== 'success');
  }).map(toUploadRow);

  return (
    <Container>
      <div style={{ paddingTop: '4rem' }}>
        <p>{ProgressText}</p>
        <ProgressBar now={ProgressValue} />
      </div>
      <div style={{ paddingTop: '4rem', paddingBottom: '1rem' }}>
        <Row>
          <h1>Resultado de Carga</h1>
        </Row>
      </div>
      <Nav variant="tabs" defaultActiveKey={`#/upload/total`}>
        <Nav.Item>
          <Nav.Link href={`#/upload/total`}>({totalFiles.length}) Total</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href={`#/upload/successful`}>({successfulFiles.length}) Carga Exitosa</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href={`#/upload/failed`}>({failedFiles.length}) Errores de Carga</Nav.Link>
        </Nav.Item>
      </Nav>
      {[`#/upload/total`].includes(location.hash) &&
        <aside>
          <ul className="upload-docs">{totalFiles}</ul>
        </aside>}
      {[`#/upload/successful`].includes(location.hash) &&
        <aside>
          <ul className="upload-docs">{successfulFiles}</ul>
        </aside>}
      {[`#/upload/failed`].includes(location.hash) &&
        <aside>
          <ul className="upload-docs">{failedFiles}</ul>
        </aside>}
    </Container>
  );
};
