import React from 'react'
import { Container, Row, Col, Button, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Navigator from '../../components/Navigator'

const ReporteCard = ({numItems,text,onClick}) =>
<Card style={{ width: '18rem' }}>
  <Card.Body className="d-flex flex-column align-items-center justify-content-between">
    <Card.Title>{numItems}</Card.Title>
    <Card.Text className="text-center">{text}</Card.Text>
    <Button variant="outline-secondary" onClick={onClick}>Configurar</Button>
  </Card.Body>
</Card>

const PanelConfiguracion = () => {
  const history = useHistory()

  return (
    <Container>
      <Navigator/>
      <Row style={{marginTop:'3rem'}}>
        <Col><h2>Mantenedores</h2></Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <ReporteCard numItems={''} text="Configuracion de Documentos" onClick={()=>{
          history.push(`/configuracion/documentos`)
        }}/>
      </Row>
      <Row style={{marginTop:'3rem'}}>
        <Col><h2>Usuarios</h2></Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <ReporteCard numItems={''} text="Configuracion de usuarios" onClick={()=>{
          history.push(`/configuracion/usuarios`)
        }}/>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => {
  return { 

  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PanelConfiguracion)