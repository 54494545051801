import React, { useState } from 'react'
import { useEffect } from 'react'
import { Container, Row, Col, Button, Card, Dropdown } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import Navigator from '../../components/Navigator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { CustomToggle, CustomMenu } from '../../components/CustomToggle'
import {
  fetchReportsSummary,
  resetReportsUsers
} from '../../store/actions/reportsActions'

const ReporteCard = ({numItems,text,onClick,loading,loadingError=false}) =>
<Card style={{ width: '18rem' }}>
  <Card.Body className="d-flex flex-column align-items-center justify-content-between">
    {!loadingError && <Card.Title>{loading?<FontAwesomeIcon icon={faSpinner} spin={true}/>:numItems}</Card.Title>}
    { loadingError && <Card.Subtitle><strong>No se pudo obtener</strong></Card.Subtitle>}
    <Card.Text className="text-center">{text}</Card.Text>
    <Button variant="outline-secondary" onClick={onClick}>Ver reporte</Button>
  </Card.Body>
</Card>

const PanelReportes = ({
  userType,
  companies,
  fetchReportsSummary,
  resetReportsUsers,
  activosSinContrato,
  terminadosSinFiniquito,
  sinDocumentos,
  menos6Liquidaciones,
  sinUltimaLiquidacion,
  sinLiquidaciones,
  sinVacaciones,
}) => {
  const [Empresas,setEmpresas] = useState([])

  const [loading,setLoading] = useState(true)
  const [loadingError,setLoadingError] = useState(false)
  useEffect(()=>{
    if(Empresas.length>0){
      setLoading(true)
      fetchReportsSummary({
        Empresas,
        OnLoadingComplete:()=>{
          setLoading(false)
          setLoadingError(false)
        },
        OnLoadingError:(err)=>{
          console.log(err)
          setLoading(false)
          setLoadingError(true)
        }
      })
    }
  },[fetchReportsSummary,setLoading,setLoadingError,Empresas])

  useEffect(()=>{
    if(companies.length>0){
      setEmpresas([companies[0]])
    }
  },[companies,setEmpresas])

  const history = useHistory()
  const gotoReport = (link) => {
    resetReportsUsers()
    history.push(link)
  }

  let EmpresasTitle = ''
  if(Empresas.length===0) { EmpresasTitle = 'Todas' }
  if(Empresas.length===1) { EmpresasTitle =  Empresas[0]}
  if(Empresas.length > 1) { EmpresasTitle = `${Empresas.length} Seleccionadas` }

  return (
    <Container>
      <Navigator/>
      <Row style={{marginTop:'3rem'}}>
        <Col><h2>Reportes de Documentos</h2></Col>
        {(companies||[]).length>0 && <Col className="form-label">
          <strong>Empresa</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {EmpresasTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              { userType === "admin" &&
                Empresas.length < companies.length &&
                <Dropdown.Item onClick={() => { setEmpresas(companies) }}>Todas</Dropdown.Item>  
              }
              {companies.map((company,index)=>
                <Dropdown.Item key={index} onClick={() => { setEmpresas([company]) }} active={(Empresas.indexOf(company)!==-1)}>{company}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        }
        <Col className="d-flex justify-content-end">
          <Button variant="link" onClick={()=>{gotoReport(`/reports/documentos`)}}>Generar Reporte Personalizado</Button>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <ReporteCard loading={loading} numItems={activosSinContrato} text="Trabajadores Activos sin Contrato" onClick={()=>{gotoReport(`/reports/trabajadores-activos-sin-contrato`)}} loadingError={loadingError}/>
        <ReporteCard loading={loading} numItems={terminadosSinFiniquito} text="Contratos Terminados sin Finiquito" onClick={()=>{gotoReport(`/reports/contratos-terminados-sin-finiquito`)}} loadingError={loadingError}/>
        <ReporteCard loading={loading} numItems={sinDocumentos} text="Trabajadores sin ningun Documento" onClick={()=>{gotoReport(`/reports/trabajadores-sin-documentos`)}} loadingError={loadingError}/>
      </Row>
      <Row style={{marginTop:'3rem'}}>
        <Col><h2>Reportes de Liquidaciones</h2></Col>
        <Col className="d-flex justify-content-end"><Button variant="link" onClick={()=>{gotoReport(`/reports/liquidaciones`)}}>Generar Reporte Personalizado</Button></Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <ReporteCard loading={loading} numItems={menos6Liquidaciones} text="Trabajadores con menos de 6 Liquidaciones" onClick={()=>{gotoReport(`/reports/trabajadores-menos-6-liquidaciones`)}} loadingError={loadingError}/>
        <ReporteCard loading={loading} numItems={sinUltimaLiquidacion} text="Trabajadores sin Ultima Liquidacion" onClick={()=>{gotoReport(`/reports/trabajadores-sin-ultima-liquidacion`)}} loadingError={loadingError}/>
        <ReporteCard loading={loading} numItems={sinLiquidaciones} text="Trabajadores sin ninguna Liquidacion" onClick={()=>{gotoReport(`/reports/trabajadores-sin-ninguna-liquidacion`)}} loadingError={loadingError}/>
      </Row>
      <Row style={{marginTop:'3rem'}}>
        <Col><h2>Reportes de Vacaciones</h2></Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <ReporteCard loading={loading} numItems={sinVacaciones} text="Trabajadores con Contratos Superiores a 12 Meses sin Comprobante de Vacaciones" onClick={()=>{gotoReport(`/reports/trabajadores-sin-vacaciones`)}} loadingError={loadingError}/>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => {
  return { 
    userType:state.auth.userType,
    companies:state.companies.companies,
    activosSinContrato:state.reportsSummary.activosSinContrato,
    terminadosSinFiniquito:state.reportsSummary.terminadosSinFiniquito,
    sinDocumentos:state.reportsSummary.sinDocumentos,
    menos6Liquidaciones:state.reportsSummary.menos6Liquidaciones,
    sinUltimaLiquidacion:state.reportsSummary.sinUltimaLiquidacion,
    sinLiquidaciones:state.reportsSummary.sinLiquidaciones,
    sinVacaciones:state.reportsSummary.sinVacaciones,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchReportsSummary,
  resetReportsUsers
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PanelReportes)