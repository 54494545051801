import React, { useState } from 'react'
import moment from 'moment'
import { Container, Row, Col, Button, Dropdown, Form, DropdownButton, InputGroup } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import Navigator from '../../components/Navigator'
import { CustomToggle, CustomMenu } from '../../components/CustomToggle'
import {
  setFiltrosReporteLiquidacionesPersonalizado
} from '../../store/actions/reportsActions'

const ReportesLiquidaciones = ({
  companies,
  costCenters,
  regions,
  sindicatos,
  setFiltrosReporteLiquidacionesPersonalizado
}) => {
  const history = useHistory()

  const [NumeroLiquidaciones,SetNumeroLiquidaciones] = useState(null)
  const [Estado,SetEstado] = useState(null)
  const [Condicion,SetCondicion] = useState(null)
  const [Empresas,setEmpresas] = useState([])
  const [CentrosCosto,setCentrosCosto] = useState([])
  const [Regiones,setRegiones] = useState([])
  const [Sindicatos,setSindicatos] = useState([])
  const [FechaTerminoSeleccionadoMonth,SetFechaTerminoSeleccionadoMonth] = useState(null)
  const [FechaTerminoSeleccionadoYear,SetFechaTerminoSeleccionadoYear] = useState(null)

  let toggleEmpresas = (empresaToToggle)=>{
    let empIndex = Empresas.indexOf(empresaToToggle)
    if(empIndex===-1){
      setEmpresas([...Empresas,empresaToToggle])
    } else {
      setEmpresas([...Empresas].filter((_,index)=>index!==empIndex))
    }
  }

  let toggleCentrosDeCostos = (costCenterToToggle)=>{
    let costCenterIndex = CentrosCosto.indexOf(costCenterToToggle)
    if(costCenterIndex===-1){
      setCentrosCosto([...CentrosCosto,costCenterToToggle])
    } else {
      setCentrosCosto([...CentrosCosto].filter((_,index)=>index!==costCenterIndex))
    }
  }

  let toggleRegiones = (regionToToggle)=>{
    let regionIndex = Regiones.indexOf(regionToToggle)
    if(regionIndex===-1){
      setRegiones([...Regiones,regionToToggle])
    } else {
      setRegiones([...Regiones].filter((_,index)=>index!==regionIndex))
    }
  }

  let toggleSindicatos = (sindicatoToToggle)=>{
    let sindicatoIndex = Sindicatos.indexOf(sindicatoToToggle)
    if(sindicatoIndex===-1){
      setSindicatos([...Sindicatos,sindicatoToToggle])
    } else {
      setSindicatos([...Sindicatos].filter((_,index)=>index!==sindicatoIndex))
    }
  }

  let EmpresasTitle = ''
  if(Empresas.length===0) { EmpresasTitle = 'Todas' }
  if(Empresas.length===1) { EmpresasTitle =  Empresas[0]}
  if(Empresas.length > 1) { EmpresasTitle = `${Empresas.length} Seleccionadas` }

  let CentrosCostoTitle = ''
  if(CentrosCosto.length===0) { CentrosCostoTitle = 'Todos' }
  if(CentrosCosto.length===1) { CentrosCostoTitle = costCenters.find(costCenter => costCenter.item === CentrosCosto[0])?.nombre || `Centro Costo ${CentrosCosto[0]}` }
  if(CentrosCosto.length > 1) { CentrosCostoTitle = `${CentrosCosto.length} Seleccionados` }

  let RegionesTitle = ''
  if(Regiones.length===0) { RegionesTitle = 'Todas' }
  if(Regiones.length===1) { RegionesTitle = regions.find(region=>region.id === Regiones[0])?.nombre }
  if(Regiones.length > 1) { RegionesTitle = `${Regiones.length} Seleccionadas` }

  let SindicatosTitle = ''
  if(Sindicatos.length===0) { SindicatosTitle = 'Todos' }
  if(Sindicatos.length===1) { SindicatosTitle = Sindicatos[0]}
  if(Sindicatos.length > 1) { SindicatosTitle = `${Sindicatos.length} Seleccionados` }

  let generarReporteActivo = (
    Estado!==null && 
    Condicion!==null && 
    NumeroLiquidaciones!==null
  )
  if(generarReporteActivo && ["Pendiente","Terminado"].includes(Estado)){
    generarReporteActivo = (FechaTerminoSeleccionadoMonth!==null && FechaTerminoSeleccionadoYear!==null)
  }

  const gotoResultado = () => {
    setFiltrosReporteLiquidacionesPersonalizado({
      Estado,
      Condicion,
      NumeroLiquidaciones,
      Empresas,
      CentrosCosto,
      Regiones,
      Sindicatos,
      FechaTerminoSeleccionadoMonth,
      FechaTerminoSeleccionadoYear,
    })
    history.push('/reports/liquidaciones/result')
  }

  const FechaTerminoMonthsOptions = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ]

  const FechaTerminoYearsOptions = Array(20).fill(true).map((_, idx) => +moment().subtract(idx, 'years').format("YYYY"))

  return (
    <Container>
      <Navigator/>
      <Row style={{marginTop:'3rem'}}>
        <Col><h2>Reportes Personalizado de Liquidaciones</h2></Col>
      </Row>
      <Row>
        <Col xs={4}>Paso 1</Col>
        <Col xs={4}>Paso 2</Col>
        <Col xs={4}>Paso 3</Col>
      </Row>
      <Row>
        <Col xs={4}>Tipo Trabajador</Col>
        <Col xs={4}>Condición</Col>
        <Col xs={4}>Seleccion de Documentos</Col>
      </Row>
      <Row>
        <Col xs={4} className="tipo-trabajador-select">
          {/*
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-trabajador" onClick={() => { SetEstado("Todos") }} />
              Todos
            </InputGroup.Prepend>
          </InputGroup>
          */}
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-trabajador" onClick={() => { 
                SetEstado("Activo")
                SetFechaTerminoSeleccionadoMonth(null)
                SetFechaTerminoSeleccionadoYear(null)
              }} />
              Activo
            </InputGroup.Prepend>
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-trabajador" onClick={() => { SetEstado("Pendiente") }} />
              Pendiente
            </InputGroup.Prepend>
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-trabajador" onClick={() => { SetEstado("Terminado") }} />
              Terminado
            </InputGroup.Prepend>
          </InputGroup>
          {["Pendiente","Terminado"].includes(Estado) &&
          <Row>
            <Col>Fecha Termino</Col>
            <Form.Group as={Col} controlId="formFechaTerminadoMonth">
              <DropdownButton id="dropdown-basic-button" title={(FechaTerminoSeleccionadoMonth!==null)?FechaTerminoMonthsOptions[FechaTerminoSeleccionadoMonth]:"Mes"} variant="outline-secondary">
                {FechaTerminoMonthsOptions.map((mes,index)=>
                  <Dropdown.Item key={index} onClick={()=>{
                    SetFechaTerminoSeleccionadoMonth(index)
                  }}>{mes}</Dropdown.Item>
                )}
              </DropdownButton>
            </Form.Group>
            <Form.Group as={Col} controlId="formFechaTerminadoYear">
              <DropdownButton id="dropdown-basic-button" title={(FechaTerminoSeleccionadoYear!==null)?`${FechaTerminoSeleccionadoYear}`:"Año"} variant="outline-secondary">
                {FechaTerminoYearsOptions.map((year,index)=>
                  <Dropdown.Item key={index} onClick={()=>{
                    SetFechaTerminoSeleccionadoYear(year)
                  }}>{year}</Dropdown.Item>
                )}
              </DropdownButton>
            </Form.Group>
          </Row>
          }
        </Col>
        <Col xs={4} className="tipo-documento-select">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-documento" onClick={() => { SetCondicion(false) }} />
              NO Tenga los Documentos
            </InputGroup.Prepend>
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-documento" onClick={() => { SetCondicion(true) }} />
              SI tenga los Documentos
            </InputGroup.Prepend>
          </InputGroup>
        </Col>
        <Col xs={4} className="tipo-numero-liquidaciones-select">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-numero-liquidaciones" onClick={() => { SetNumeroLiquidaciones(1) }} />
              Última Liquidacion
            </InputGroup.Prepend>
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-numero-liquidaciones" onClick={() => { SetNumeroLiquidaciones(3) }} />
              Últimas 3 Liquidaciones
            </InputGroup.Prepend>
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-numero-liquidaciones" onClick={() => { SetNumeroLiquidaciones(6) }} />
              Últimas 6 Liquidaciones
            </InputGroup.Prepend>
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-numero-liquidaciones" onClick={() => { SetNumeroLiquidaciones(12) }} />
              Últimas 12 Liquidaciones
            </InputGroup.Prepend>
          </InputGroup>
        </Col>
      </Row>
      <Row style={{marginTop:'3rem'}}>
        <Col xs={4}>Paso 4</Col>
      </Row>
      <Row>
        <Col><h2>Filtros Generales</h2></Col>
      </Row>
      <Row>
        <Col className="form-label">
          <strong>Empresa</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {EmpresasTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                Empresas.length < companies.length &&
                <Dropdown.Item onClick={() => { setEmpresas(companies) }}>Todas</Dropdown.Item>  
              }
              {
                Empresas.length === companies.length &&
                <Dropdown.Item onClick={() => { setEmpresas([]) }}>Ninguna</Dropdown.Item>
              }
              {companies.map((company,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleEmpresas(company) }} active={(Empresas.indexOf(company)!==-1)}>{company}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="form-label">
          <strong>Centro de Costo</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {CentrosCostoTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                CentrosCosto.length < costCenters.length &&
                <Dropdown.Item onClick={() => { setCentrosCosto(costCenters.map(({item})=>item)) }}>Todas</Dropdown.Item>  
              }
              {
                CentrosCosto.length === costCenters.length &&
                <Dropdown.Item onClick={() => { setCentrosCosto([]) }}>Ninguno</Dropdown.Item>
              }
              {costCenters.map((costCenter,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleCentrosDeCostos(costCenter.item) }} active={(CentrosCosto.indexOf(costCenter.item)!==-1)}>{costCenter.nombre}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="form-label">
          <strong>Región</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {RegionesTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                Regiones.length < regions.length &&
                <Dropdown.Item onClick={() => { setRegiones(regions.map(({id})=>id)) }}>Todas</Dropdown.Item>  
              }
              {
                Regiones.length === regions.length &&
                <Dropdown.Item onClick={() => { setRegiones([]) }}>Ninguna</Dropdown.Item>
              }
              {regions.map((region,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleRegiones(region.id) }} active={(Regiones.indexOf(region.id)!==-1)}>{region.nombre}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="form-label">
          <strong>Sindicato</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {SindicatosTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                Sindicatos.length < sindicatos.length &&
                <Dropdown.Item onClick={() => { setSindicatos(sindicatos.map(({sindicato})=>sindicato)) }}>Todos</Dropdown.Item>  
              }
              {
                Sindicatos.length === sindicatos.length &&
                <Dropdown.Item onClick={() => { setSindicatos([]) }}>Ninguna</Dropdown.Item>
              }
              {sindicatos.map((sindicato,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleSindicatos(sindicato.sindicato) }} active={(Sindicatos.indexOf(sindicato.sindicato)!==-1)}>{sindicato.sindicato}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row style={{marginTop:'3rem'}}>
        <Button variant="outline-secondary" onClick={gotoResultado} active={generarReporteActivo} disabled={!generarReporteActivo}>Generar Reporte</Button>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => {
  return { 
    companies:state.companies.companies,
    costCenters:state.costCenters.costCenters,
    regions:state.regions.regions,
    sindicatos:state.sindicatos.sindicatos,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  setFiltrosReporteLiquidacionesPersonalizado
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ReportesLiquidaciones)