import { Col, Row, Image } from 'react-bootstrap'
import moment from 'moment'

export const UserDetails = ({details}) => {
  return (
    <Row className="UserDetails">
      <Col sm={2}>
        <Image src={((details.foto || '').toLowerCase().match(/.jpg$|.png$/i)!==null)?details.foto:'/blank-profile-picture.png'}/>
      </Col>
      <Col>
      <Row>
        <h1>{details.nombre} {details.apellidoPate} {details.apellidoMate}</h1>
      </Row>
      <Row>
        <h2>{details.empresa} - {details.nombreCentroCosto}</h2>
      </Row>
      <Row>
        <h3>{(details.fechaTerminoContrato===null || moment(details.fechaTerminoContrato).isAfter(moment()))?`VIGENTE`:`NO VIGENTE`}</h3>
      </Row>
      <Row>
        <Col style={{paddingLeft:'0px'}}>
          <h2>Fecha Ingreso:{moment(details.fechaInicioContrato).format("DD/MM/YYYY")}</h2>
        </Col>
        <Col>
          <h2>Fecha Termino:{(details.fechaTerminoContrato===null)?`VIGENTE`:moment(details.fechaTerminoContrato).format("DD/MM/YYYY")}</h2>
        </Col>
      </Row>
      </Col>
    </Row>
  )
}