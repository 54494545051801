import React, { useState, useCallback } from 'react'
import { useEffect } from 'react'
import { Container, Row, Col, Button, Table, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link, useHistory } from 'react-router-dom'
import Navigator from '../../components/Navigator'
import RutComponent from 'react-rut'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  fetchReporteLiquidacionesPersonalizado
} from '../../store/actions/reportsActions'
import { CustomFechaIngresoRangePicker } from '../../components/CustomFechaIngresoRangePicker'

const ReportesLiquidacionesResultados = ({
  fetchReporteLiquidacionesPersonalizado,

  employees,
  totalPages,
  TotalRecords,
  StartRecord,

  Estado,
  FechaTerminoSeleccionadoMonth,
  FechaTerminoSeleccionadoYear,
  Condicion,
  NumeroLiquidaciones,
  Empresas,
  CentrosCosto,
  Regiones,
  Sindicatos,
}) => {

  const history = useHistory()

  const [doFetchUsers, setDoFetchUsers] = useState(true)
  const [Nombre, setNombre] = useState('')
  const [Rut,setRut] = useState('')
  const [PageSize] = useState(20)
  const [PageNumber,setPageNumber] = useState(1)
  const [FechaIngreso,setFechaIngreso] = useState('')
  const [FechaIngreso2,setFechaIngreso2] = useState('')

  const [fetchingInProgress,setFetchingInProgress] = useState(true)

  let EstadosMap = {
    'A':"Activo",
    'T':"Terminado",
    'P':"Pendiente"
  }
  const EstadosInverseMap = useCallback(estadoContrato=>{
    const estadosContratoInverso = {
      "Activo":['A'],
      "Terminado":['T'],
      "Pendiente":['P'],
      "Todos":[]
    }
    return estadosContratoInverso[estadoContrato]
  },[])

  useEffect(()=>{
    if(!doFetchUsers) return
    setFetchingInProgress(true)
    setDoFetchUsers(false)
    fetchReporteLiquidacionesPersonalizado({
      Estados:EstadosInverseMap(Estado),
      FechaTerminoSeleccionadoMonth,
      FechaTerminoSeleccionadoYear,
      Condicion,
      NumeroLiquidaciones,
      Empresas,
      CentrosCosto,
      Regiones,
      Sindicatos,
      Nombre,
      Rut:Rut.replaceAll('.','').split("-")[0],
      PageSize,
      PageNumber,
      FechaIngreso,
      FechaIngreso2,
      history,
      OnFetchComplete:(error)=>{
        setFetchingInProgress(false)
      }
    })
  },[
    doFetchUsers,
    setFetchingInProgress,
    setDoFetchUsers,
    fetchReporteLiquidacionesPersonalizado,
    EstadosInverseMap,
    Estado,
    FechaTerminoSeleccionadoMonth,
    FechaTerminoSeleccionadoYear,
    Condicion,
    NumeroLiquidaciones,
    Empresas,
    CentrosCosto,
    Regiones,
    Sindicatos,
    Nombre,
    Rut,
    PageSize,
    PageNumber,
    FechaIngreso,
    FechaIngreso2,
    history,
  ])
  
  const exportarExcel=()=>{
    let url = `${window.location.origin}/reports/liquidaciones/export`
    let params = []
    let RutToUse = Rut.replaceAll('.','').split("-")[0]
    if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
    if(CentrosCosto.length>0) params.push(`CentrosCosto=${CentrosCosto.join(',')}`)
    if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
    if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
    if(Regiones.length>0) params.push(`Regiones=${Regiones.join(',')}`)
    if(Sindicatos.length>0) params.push(`Sindicatos=${Sindicatos.join(',')}`)
    if((RutToUse || '')!=='') params.push(`Rut=${RutToUse}`)
    if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
    if(Estado!=="Todos"){
      params.push(`Estados=${EstadosInverseMap(Estado).join(',')}`)
    }
    if(FechaTerminoSeleccionadoMonth!==null){
      params.push(`FechaTerminoSeleccionadoMonth=${FechaTerminoSeleccionadoMonth}`)
    }
    if(FechaTerminoSeleccionadoYear!==null){
      params.push(`FechaTerminoSeleccionadoYear=${FechaTerminoSeleccionadoYear}`)
    }
    params.push(`NumeroLiquidaciones=${NumeroLiquidaciones}`)
    params.push(`Condicion=${Condicion}`)
    url = `${url}?${params.join('&')}`
    window.open(url)
  }

  const gotoPage=(page)=>{
    setPageNumber(page)
    setDoFetchUsers(true)
  }

  let pageMin = Math.max(PageNumber-3,1)
  let pageMax = Math.min(pageMin+4,totalPages)
  let pagesToNavigate = []
  if(PageNumber>1){
    pagesToNavigate.push({text:'\u00AB',page:1})
    pagesToNavigate.push({text:'\u003C',page:PageNumber-1})
  }
  for(let i = pageMin; i <= pageMax; i++){
    pagesToNavigate.push({text:`${i}`,page:i})
  }
  if(PageNumber<totalPages){
    pagesToNavigate.push({text:'\u003E',page:PageNumber+1})
    pagesToNavigate.push({text:'\u00BB',page:totalPages})
  }

  const onKeyUpFilter=(e)=>{
    if(e.keyCode === 13){
      gotoPage(1)
    }
  }

  const fechaIngresoApply = (event, picker) => {
    setFechaIngreso(picker.startDate.format('YYYYMMDD'))
    setFechaIngreso2(picker.endDate.format('YYYYMMDD'))
    picker.element.val(
      picker.startDate.format('DD/MM/YYYY') +
        ' - ' +
        picker.endDate.format('DD/MM/YYYY')
    )
    gotoPage(1)
  }

  const fechaIngresoCancel = (event, picker) => {
    setFechaIngreso('')
    setFechaIngreso2('')
    picker.element.val('');
    gotoPage(1)
  }

  return (
    <Container>
      <Navigator/>
      <Row style={{marginTop:'3rem'}}>
        <Col><h6>Reportes Personalizado de Liquidaciones</h6></Col>
      </Row>
      <Row>
        <Col><h3>Resultado de Reporte</h3></Col>
      </Row>
      <Row>
        <Col className="form-label">
          <Form.Label style={{marginBottom:'0'}}><strong>Filtro Nombre</strong></Form.Label>
          <Form.Control type="name" placeholder="Nombre" defaultValue={Nombre} onChange={(e)=>{setNombre(e.target.value)}} onKeyUp={onKeyUpFilter}/>
        </Col>
        <Col className="form-label">
          <Form.Label style={{marginBottom:'0'}}><strong>Filtro RUT</strong></Form.Label>
          <RutComponent value={Rut} onChange={(e)=>{setRut(e.target.value)}} >
            <Form.Control type="rut" placeholder="Rut" onKeyUp={onKeyUpFilter}/>
          </RutComponent>
        </Col>
        <CustomFechaIngresoRangePicker 
          onApply={fechaIngresoApply}
          onCancel={fechaIngresoCancel}
        />
        {!fetchingInProgress &&
        <Col xs={{offset: 2}}>
          <Button variant="outline-secondary" style={{position:"absolute",bottom:"1rem",right:"1rem"}} onClick={exportarExcel}>Exportar a Excel</Button>
        </Col>
        }
      </Row>
      {fetchingInProgress &&
      <Row className="justify-content-sm-center">
        <FontAwesomeIcon icon={faSpinner} spin={true}/>
      </Row>
      }
      {!fetchingInProgress &&
      <Row>
        <Col xs={{offset:8, span:4}} style={{textAlign:'end'}}>
          <p>Registros {Math.min(StartRecord+1,TotalRecords)}/{StartRecord+employees.length} de {TotalRecords}</p>
        </Col>
      </Row>
      }
      {!fetchingInProgress &&
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Empresa</th>
            <th>C. de Costo</th>
            <th>RUT</th>
            <th>Nombre y Apellido</th>
            <th>Región</th>
            <th>Fecha Ingreso</th>
            <th>Fecha Termino</th>
            <th>Estado</th>
            <th>Documentos</th>
            <th>Liquidaciones</th>
            <th>Sindicato</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((user,index)=>
          <tr key={index}>
            <td>{user.empresa}</td>
            <td>{user.centroCosto}</td>
            <td>{user.rut}</td>
            <td>{user.nombre} {user.apellidoPate}</td>
            <td>{user.region}</td>
            <td>{moment(user.fechaIngreso).format("DD / MM / YYYY")}</td>
            <td>{(user.fechaTermino!==undefined && user.fechaTermino!==null)?moment(user.fechaTermino).format("DD / MM / YYYY"):''}</td>
            <td>{EstadosMap[user.Estado]}</td>
            <td>{user.numeroDocumentos}</td>
            <td>{user.numeroLiquidaciones}</td>
            <td>{user.sindicato}</td>
            <td><Link target="_blank" to={`/detallesPersonal/liquidaciones?Contrato=${user.ContratoId}&Rut=${user.rut}`}>Ver detalle</Link></td>
          </tr>
          )}
        </tbody>
      </Table>
      }
      {!fetchingInProgress &&
      <Row style={{paddingLeft:'30%',paddingRight:'30%'}}>
        {pagesToNavigate.map((page,index)=>
          <Col key={index}><button className="fake-link" style={{color:(page.page===PageNumber)?'red':'blue'}} onClick={()=>{gotoPage(page.page)}}>{page.text}</button></Col>  
        )}
      </Row>
      }
    </Container>
  )
}

const mapStateToProps = state => {
  return { 
    employees:state.reports.employees,
    totalPages:state.reports.totalPages,
    TotalRecords:state.reports.TotalRecords,
    StartRecord:state.reports.StartRecord, 

    Estado:state.reporteLiquidaciones.Estado,
    Condicion:state.reporteLiquidaciones.Condicion,
    NumeroLiquidaciones:state.reporteLiquidaciones.NumeroLiquidaciones,
    Empresas:state.reporteLiquidaciones.Empresas,
    CentrosCosto:state.reporteLiquidaciones.CentrosCosto,
    Regiones:state.reporteLiquidaciones.Regiones,
    Sindicatos:state.reporteLiquidaciones.Sindicatos,
    FechaTerminoSeleccionadoMonth:state.reporteLiquidaciones.FechaTerminoSeleccionadoMonth,
    FechaTerminoSeleccionadoYear:state.reporteLiquidaciones.FechaTerminoSeleccionadoYear,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchReporteLiquidacionesPersonalizado
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ReportesLiquidacionesResultados)