import { API_ENDPOINT } from '../store/actions/APIEndpoint'
import { fileNameValidationRegexp } from '../regexp'

export const getNewUploadBatchId = async () => {
  let query = `${API_ENDPOINT}/users/attachment/newbatchid`

  let { batchId } = await (await fetch(query)).json() 
  return batchId
}

export const uploadToS3 = async (
  file,
  url,
  addToResultQueue
) => {
  let fileData = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      if (!!reader.result) resolve(reader.result)
      else reject(Error("Failed converting file"))
    }
  })

  let upload = await new Promise((resolve,reject)=>{
      let xhr = new XMLHttpRequest()
      xhr.onload = ()=>{
          resolve(xhr.status)
      }
      xhr.onerror = ()=>{
          reject(new TypeError("Network request failed"))
      }
      xhr.open('PUT',url)
      xhr.setRequestHeader('Content-Type','application/octet-stream')
      xhr.send(fileData)
  })

  if(upload===200){
    console.log('upload success')
    addToResultQueue(file.name,{status:'success',message:'Correcto'})
  } else {
    console.log('upload fail')
    addToResultQueue(file.name,{status:'error',message:'algo salio mal(failed put method)'})
  }
}

export const onUploadBegin = async ({
  batchId,
  file,
  company,
  costCenter,
  Contrato,
  addToUploadQueue,
  addUserToFile,
  addCostCenterToFile,
  addToResultQueue,
}) => {
  let parts = new RegExp(fileNameValidationRegexp).exec(file.name)
  let Empresa = company
  let CentroCosto = costCenter
  let Rut = `${parts[2]}-${parts[3]}`
  let FileName = file.name
  addToUploadQueue(FileName)

  let query = `${API_ENDPOINT}/users/attachment/signedurl`
  let params = []
  params.push(`batchId=${batchId}`)
  if((Empresa || '')!=='') params.push(`Empresa=${Empresa}`)
  if((CentroCosto || '')!=='') params.push(`CentroCosto=${CentroCosto}`)
  if((Contrato || '')!=='') params.push(`Contrato=${Contrato}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((FileName || '')!=='') params.push(`FileName=${FileName}`)
  query = `${query}?${params.join('&')}`

  let result = await fetch(query)

  if(result.status===200){
    let uploadData = await result.json() 
  
    let { error, user, url } = uploadData
  
    addUserToFile(FileName,user)
  
    if( error !== undefined){
      addToResultQueue(FileName,{status:'error',message:error})
    } else {
      if(CentroCosto===undefined){
        addCostCenterToFile(FileName,uploadData.CentroCosto)
      }
      await uploadToS3(
        file,
        url,
        addToResultQueue
      )
    }
  } else {
    addToResultQueue(FileName,{status:'error',message:`no se puede subir ${FileName} (signed url)`})
  }
}