import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { 
  fetchReportsCustomLiquidacionesExportUrl
} from '../../store/actions/reportsActions'

const ExportarReporteLiquidacionesPersonalizado = ({
  fetchReportsCustomLiquidacionesExportUrl
}) =>{

  let query = new URLSearchParams(useLocation().search)
  
  const [Empresas] = useState(query.get("Empresas"))
  const [CentrosCosto] = useState(query.get("CentrosCosto"))
  const [FechaIngreso] = useState(query.get("FechaIngreso"))
  const [FechaIngreso2] = useState(query.get("FechaIngreso2"))
  const [Regiones] = useState(query.get("Regiones"))
  const [Sindicatos] = useState(query.get("Sindicatos"))
  const [Rut] = useState(query.get("Rut"))
  const [Nombre] = useState(query.get("Nombre"))
  const [Estados] = useState(query.get("Estados"))
  const [Condicion] = useState(query.get("Condicion"))
  const [NumeroLiquidaciones] = useState(query.get("NumeroLiquidaciones"))
  const [FechaTerminoSeleccionadoMonth] = useState(query.get("FechaTerminoSeleccionadoMonth"))
  const [FechaTerminoSeleccionadoYear] = useState(query.get("FechaTerminoSeleccionadoYear"))

  useEffect(()=>{
    fetchReportsCustomLiquidacionesExportUrl({
      Empresas,
      CentrosCosto,
      FechaIngreso,
      FechaIngreso2,
      Regiones,
      Sindicatos,
      Rut,
      Nombre,
      Estados,
      FechaTerminoSeleccionadoMonth,
      FechaTerminoSeleccionadoYear,
      Condicion,
      NumeroLiquidaciones
    })
  },[Empresas,CentrosCosto,FechaIngreso,FechaIngreso2,Regiones,Sindicatos,Rut,Nombre,Estados,FechaTerminoSeleccionadoMonth,FechaTerminoSeleccionadoYear,Condicion,NumeroLiquidaciones,fetchReportsCustomLiquidacionesExportUrl])

  return (
    <div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchReportsCustomLiquidacionesExportUrl
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ExportarReporteLiquidacionesPersonalizado)