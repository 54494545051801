import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form, Image } from 'react-bootstrap'
import { connect } from 'react-redux'
import { signIn } from '../store/actions/authActions'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'

const Login = ({
  signIn,
  signedIn,
  resetPassword,
  userType,
  AuthUserName
}) => {
  const [UserName,setUserName] = useState('')
  const [Pass,setPass] = useState('')
  const [Error,setError] = useState('')
  const history = useHistory()

  const doSignIn = () => {
    if(UserName.length>0 && Pass.length>0){
      signIn({UserName,Pass,OnError:(err)=>{
        setError(err)
      }})
    }
  }

  useEffect(()=>{
    if(signedIn){
      if(userType!=='rut'){
        history.push('/dashboard')
      } else {
        history.push(`/detallesPersonal/liquidaciones?Rut=${AuthUserName}`)
      }
      
    }
    if(resetPassword){
      history.push('/resetpassword')
    }
  },[signedIn,resetPassword,AuthUserName,history,userType])

  return (
    <Container>
      <Row className="justify-content-sm-center">
        <Image src="/logo512.png" style={{height:'250px'}}/>
      </Row>
      <Row className="justify-content-sm-center"><h1>Bienvenido a Docuus</h1></Row>
      <Row className="justify-content-sm-center">
        <Col sm={6}>
          <Form.Group as={Col} controlId="formUserName" style={{marginBottom:'0'}}>
            <Form.Label style={{marginBottom:'0'}}><strong>Usuario</strong></Form.Label>
            <Form.Control type="name" placeholder="Correo o Rut(XXXXXXXX-X)" value={UserName} onChange={(e)=>{setError('');setUserName(e.target.value)}}/>
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-sm-center">
        <Col sm={6}>
          <Form.Group as={Col} controlId="formPass" style={{marginBottom:'0'}}>
            <Form.Label style={{marginBottom:'0'}}><strong>Contraseña</strong></Form.Label>
            <Form.Control type="password" placeholder="Contraseña" value={Pass} onKeyUp={(e)=>{if(["Enter"].includes(e.key)){doSignIn()}}} onChange={(e)=>{setError('');setPass(e.target.value)}}/>
          </Form.Group>
        </Col>
      </Row>
      {Error!=='' &&
      <Row className="justify-content-sm-center">
        <Col sm={6}>
          <Form.Group as={Col} controlId="formError" style={{
              marginBottom:'0',
              backgroundColor:'antiquewhite',
              marginTop:'1rem',
              marginLeft:'1rem',
              width:'96%'
            }}>
            <Form.Label style={{marginBottom:'0'}}><strong>Error:</strong> {Error}</Form.Label>
          </Form.Group>
        </Col>
      </Row>
      }
      <Row sm={4} className="justify-content-sm-center" style={{marginTop:'1rem'}}>
        <Button variant="outline-secondary" onClick={doSignIn} disabled={UserName.length===0 && Pass.length===0}>Ingresar</Button>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    signedIn:state.auth.signedIn,
    resetPassword:state.auth.resetPassword,
    userType:state.auth.userType,
    AuthUserName:state.auth.UserName,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  signIn
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Login)