import { 
  SET_COMPANIES
} from '../actions/actionTypes'

const initialState = {
  companies:[],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANIES:
      return {
        companies: [...action.companies]
      };
    default:
      return state;
  }
} 

export default reducer