import { 
  SET_SINDICATOS
} from '../actions/actionTypes'

const initialState = {
  sindicatos:[],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SINDICATOS:
      return {
        sindicatos: [...action.sindicatos]
      };
    default:
      return state;
  }
} 

export default reducer