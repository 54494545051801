import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Dropdown, Table, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Navigator from './Navigator'
import { CustomMenu, CustomToggle } from './CustomToggle'
import RutComponent from 'react-rut'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { CustomFechaIngresoRangePicker } from './CustomFechaIngresoRangePicker'

const ReporteBody = (
{
  Report,
  ReportTitle,
  filtersToHide=[],

  companies,
  costCenters,
  regions,
  sindicatos,

  employees,
  totalPages,
  TotalRecords,
  StartRecord,

  fetchReportData
}) => {

  const [doFetchUsers, setDoFetchUsers] = useState(true)
  const [Nombre, setNombre] = useState('')
  const [Rut,setRut] = useState('')
  const [PageSize] = useState(20)
  const [PageNumber,setPageNumber] = useState(1)
  const [Empresas,setEmpresas] = useState([])
  const [CentrosCosto,setCentrosCosto] = useState([])
  const [FechaIngreso,setFechaIngreso] = useState('')
  const [FechaIngreso2,setFechaIngreso2] = useState('')
  const [Regiones,setRegiones] = useState([])
  const [Sindicatos,setSindicatos] = useState([])
  const [Estados,setEstados] = useState([])

  const [fetchingInProgress,setFetchingInProgress] = useState(true)

  let EstadosValues = ['A','T','P']
  let EstadosMap = {
    'A':"Activo",
    'T':"Terminado",
    'P':"Pendiente"
  }

  useEffect(()=>{
    if(!doFetchUsers) return
    setFetchingInProgress(true)
    setDoFetchUsers(false)
    fetchReportData({
      Empresas,
      CentrosCosto,
      FechaIngreso,
      FechaIngreso2,
      Regiones,
      Sindicatos,
      Rut:Rut.replaceAll('.','').split("-")[0],
      Nombre,
      Estados,
      PageSize,
      PageNumber,
      OnFetchComplete:(error)=>{
        setFetchingInProgress(false)
      }
    })
  },[doFetchUsers,Empresas,CentrosCosto,FechaIngreso,FechaIngreso2,Regiones,Sindicatos,Rut,Nombre,PageSize,PageNumber,Estados,fetchReportData,setFetchingInProgress])
  
  const exportarExcel=()=>{
    let url = `${window.location.origin}/reports/export`
    let params = []
    let RutToUse = Rut.replaceAll('.','').split("-")[0]
    if(Report.length>0) params.push(`Report=${Report}`)
    if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
    if(CentrosCosto.length>0) params.push(`CentrosCosto=${CentrosCosto.join(',')}`)
    if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
    if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
    if(Regiones.length>0) params.push(`Regiones=${Regiones.join(',')}`)
    if(Sindicatos.length>0) params.push(`Sindicatos=${Sindicatos.join(',')}`)
    if((RutToUse || '')!=='') params.push(`Rut=${RutToUse}`)
    if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
    if(Estados.length>0) params.push(`Estados=${Estados.join(',')}`)
    url = `${url}?${params.join('&')}`
    window.open(url)
  }

  const gotoPage=(page)=>{
    setPageNumber(page)
    setDoFetchUsers(true)
  }

  let pageMin = Math.max(PageNumber-3,1)
  let pageMax = Math.min(pageMin+4,totalPages)
  let pagesToNavigate = []
  if(PageNumber>1){
    pagesToNavigate.push({text:'\u00AB',page:1})
    pagesToNavigate.push({text:'\u003C',page:PageNumber-1})
  }
  for(let i = pageMin; i <= pageMax; i++){
    pagesToNavigate.push({text:`${i}`,page:i})
  }
  if(PageNumber<totalPages){
    pagesToNavigate.push({text:'\u003E',page:PageNumber+1})
    pagesToNavigate.push({text:'\u00BB',page:totalPages})
  }

  let toggleEmpresas = (empresaToToggle)=>{
    let empIndex = Empresas.indexOf(empresaToToggle)
    if(empIndex===-1){
      setEmpresas([...Empresas,empresaToToggle])
    } else {
      setEmpresas([...Empresas].filter((_,index)=>index!==empIndex))
    }
    gotoPage(1)
  }

  let toggleCentrosDeCostos = (costCenterToToggle)=>{
    let costCenterIndex = CentrosCosto.indexOf(costCenterToToggle)
    if(costCenterIndex===-1){
      setCentrosCosto([...CentrosCosto,costCenterToToggle])
    } else {
      setCentrosCosto([...CentrosCosto].filter((_,index)=>index!==costCenterIndex))
    }
    gotoPage(1)
  }

  let toggleRegiones = (regionToToggle)=>{
    let regionIndex = Regiones.indexOf(regionToToggle)
    if(regionIndex===-1){
      setRegiones([...Regiones,regionToToggle])
    } else {
      setRegiones([...Regiones].filter((_,index)=>index!==regionIndex))
    }
    gotoPage(1)
  }

  let toggleSindicatos = (sindicatoToToggle)=>{
    let sindicatoIndex = Sindicatos.indexOf(sindicatoToToggle)
    if(sindicatoIndex===-1){
      setSindicatos([...Sindicatos,sindicatoToToggle])
    } else {
      setSindicatos([...Sindicatos].filter((_,index)=>index!==sindicatoIndex))
    }
    gotoPage(1)
  }

  let toggleEstados = (estadoToToggle)=>{
    let estadoIndex = Estados.indexOf(estadoToToggle)
    if(estadoIndex===-1){
      setEstados([...Estados,estadoToToggle])
    } else {
      setEstados([...Estados].filter((_,index)=>index!==estadoIndex))
    }
    gotoPage(1)
  }

  const onKeyUpFilter=(e)=>{
    if(e.keyCode === 13){
      gotoPage(1)
    }
  }

  const fechaIngresoApply = (event, picker) => {
    setFechaIngreso(picker.startDate.format('YYYYMMDD'))
    setFechaIngreso2(picker.endDate.format('YYYYMMDD'))
    picker.element.val(
      picker.startDate.format('DD/MM/YYYY') +
        ' - ' +
        picker.endDate.format('DD/MM/YYYY')
    )
    gotoPage(1)
  }

  const fechaIngresoCancel = (event, picker) => {
    setFechaIngreso('')
    setFechaIngreso2('')
    picker.element.val('')
    gotoPage(1)
  }

  let EmpresasTitle = ''
  if(Empresas.length===0) { EmpresasTitle = 'Todas' }
  if(Empresas.length===1) { EmpresasTitle =  Empresas[0]}
  if(Empresas.length > 1) { EmpresasTitle = `${Empresas.length} Seleccionadas` }

  let CentrosCostoTitle = ''
  if(CentrosCosto.length===0) { CentrosCostoTitle = 'Todos' }
  if(CentrosCosto.length===1) { CentrosCostoTitle = costCenters.find(costCenter => costCenter.item === CentrosCosto[0])?.nombre || `Centro Costo ${CentrosCosto[0]}` }
  if(CentrosCosto.length > 1) { CentrosCostoTitle = `${CentrosCosto.length} Seleccionados` }

  let RegionesTitle = ''
  if(Regiones.length===0) { RegionesTitle = 'Todas' }
  if(Regiones.length===1) { RegionesTitle = regions.find(region=>region.id === Regiones[0])?.nombre }
  if(Regiones.length > 1) { RegionesTitle = `${Regiones.length} Seleccionadas` }

  let SindicatosTitle = ''
  if(Sindicatos.length===0) { SindicatosTitle = 'Todos' }
  if(Sindicatos.length===1) { SindicatosTitle = Sindicatos[0]}
  if(Sindicatos.length > 1) { SindicatosTitle = `${Sindicatos.length} Seleccionados` }

  let EstadosTitle = ''
  if(Estados.length===0) { EstadosTitle = 'Todos' }
  if(Estados.length===1) { EstadosTitle = EstadosMap[Estados[0]]}
  if(Estados.length > 1) { EstadosTitle = `${Estados.length} Seleccionados` }

  return (
    <Container>
      <Navigator/>
      <Row>
        <h6>Reporte</h6>
      </Row>
      <Row>
        <h4>{ReportTitle}</h4>
      </Row>
      <Row>
        <Col className="form-label">
          <strong>Empresa</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {EmpresasTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                Empresas.length < companies.length &&
                <Dropdown.Item onClick={() => { setEmpresas(companies);gotoPage(1) }}>Todas</Dropdown.Item>  
              }
              {
                Empresas.length === companies.length &&
                <Dropdown.Item onClick={() => { setEmpresas([]);gotoPage(1) }}>Ninguna</Dropdown.Item>
              }
              {companies.map((company,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleEmpresas(company) }} active={(Empresas.indexOf(company)!==-1)}>{company}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="form-label">
          <strong>Centro de Costo</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {CentrosCostoTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                CentrosCosto.length < costCenters.length &&
                <Dropdown.Item onClick={() => { setCentrosCosto(costCenters.map(({item})=>item));gotoPage(1) }}>Todas</Dropdown.Item>  
              }
              {
                CentrosCosto.length === costCenters.length &&
                <Dropdown.Item onClick={() => { setCentrosCosto([]);gotoPage(1) }}>Ninguno</Dropdown.Item>
              }
              {costCenters.map((costCenter,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleCentrosDeCostos(costCenter.item) }} active={(CentrosCosto.indexOf(costCenter.item)!==-1)}>{costCenter.nombre}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <CustomFechaIngresoRangePicker 
          onApply={fechaIngresoApply}
          onCancel={fechaIngresoCancel}
        />
        <Col className="form-label">
          <strong>Región</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {RegionesTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                Regiones.length < regions.length &&
                <Dropdown.Item onClick={() => { setRegiones(regions.map(({id})=>id));gotoPage(1) }}>Todas</Dropdown.Item>  
              }
              {
                Regiones.length === regions.length &&
                <Dropdown.Item onClick={() => { setRegiones([]);gotoPage(1) }}>Ninguna</Dropdown.Item>
              }
              {regions.map((region,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleRegiones(region.id) }} active={(Regiones.indexOf(region.id)!==-1)}>{region.nombre}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="form-label">
          <strong>Sindicato</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {SindicatosTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                Sindicatos.length < sindicatos.length &&
                <Dropdown.Item onClick={() => { setSindicatos(sindicatos.map(({sindicato})=>sindicato));gotoPage(1) }}>Todos</Dropdown.Item>  
              }
              {
                Sindicatos.length === sindicatos.length &&
                <Dropdown.Item onClick={() => { setSindicatos([]);gotoPage(1) }}>Ninguna</Dropdown.Item>
              }
              {sindicatos.map((sindicato,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleSindicatos(sindicato.sindicato) }} active={(Sindicatos.indexOf(sindicato.sindicato)!==-1)}>{sindicato.sindicato}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <hr/>
      <Row>
        <Form.Group as={Col} controlId="formNombre">
          <Form.Label><strong>Filtro Nombre</strong></Form.Label>
          <Form.Control type="name" placeholder="Nombre" defaultValue={Nombre} onChange={(e)=>{setNombre(e.target.value)}} onKeyUp={onKeyUpFilter}/>
        </Form.Group>
        <Form.Group as={Col} controlId="formRut">
          <Form.Label><strong>Filtro RUT</strong></Form.Label>
          <RutComponent value={Rut} onChange={(e)=>{setRut(e.target.value)}} >
            <Form.Control type="rut" placeholder="Rut" onKeyUp={onKeyUpFilter}/>
          </RutComponent>
        </Form.Group>
        {!filtersToHide.includes("Estado") &&
        <Col className="form-label">
          <strong>Estado</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {EstadosTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                Estados.length < EstadosValues.length &&
                <Dropdown.Item onClick={() => { setEstados(EstadosValues);gotoPage(1) }}>Todos</Dropdown.Item>  
              }
              {
                Estados.length === EstadosValues.length &&
                <Dropdown.Item onClick={() => { setEstados([]);gotoPage(1) }}>Ninguno</Dropdown.Item>
              }
              {EstadosValues.map((estado,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleEstados(estado) }} active={(Estados.indexOf(estado)!==-1)}>{EstadosMap[estado]}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        }
      </Row>
      {fetchingInProgress &&
      <Row className="justify-content-sm-center">
        <FontAwesomeIcon icon={faSpinner} spin={true}/>
      </Row>
      }
      {!fetchingInProgress &&
      <Row>
        <Col xs={{offset: 10,span:2}} style={{textAlign:'end'}}>
          <Button variant="outline-secondary" onClick={exportarExcel}>Exportar a Excel</Button>
        </Col>
      </Row>
      }
      {!fetchingInProgress &&
      <Row>
        <Col xs={{offset:8, span:4}} style={{textAlign:'end'}}>
          <p>Registros {Math.min(StartRecord+1,TotalRecords)}/{StartRecord+employees.length} de {TotalRecords}</p>
        </Col>
      </Row>
      }
      {!fetchingInProgress &&
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Empresa</th>
            <th>C. de Costo</th>
            <th>RUT</th>
            <th>Nombre y Apellido</th>
            <th>Región</th>
            <th>Fecha Ingreso</th>
            <th>Fecha Termino</th>
            <th>Estado</th>
            <th>Documentos</th>
            <th>Liquidaciones</th>
            <th>Sindicato</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((user,index)=>
          <tr key={index}>
            <td>{user.empresa}</td>
            <td>{user.centroCosto}</td>
            <td>{user.rut}</td>
            <td>{user.nombre} {user.apellidoPate}</td>
            <td>{user.region}</td>
            <td>{moment(user.fechaIngreso).format("DD / MM / YYYY")}</td>
            <td>{(user.fechaTermino!==undefined && user.fechaTermino!==null)?moment(user.fechaTermino).format("DD / MM / YYYY"):''}</td>
            <td>{EstadosMap[user.Estado]}</td>
            <td>{user.numeroDocumentos}</td>
            <td>{user.numeroLiquidaciones}</td>
            <td>{user.sindicato}</td>
            <td><Link target="_blank" to={`/detallesPersonal/liquidaciones?Contrato=${user.ContratoId}&Rut=${user.rut}`}>Ver detalle</Link></td>
          </tr>
          )}
        </tbody>
      </Table>
      }
      {!fetchingInProgress &&
      <Row style={{paddingLeft:'30%',paddingRight:'30%'}}>
        {pagesToNavigate.map((page,index)=>
          <Col key={index}><button className="fake-link" style={{color:(page.page===PageNumber)?'red':'blue'}} onClick={()=>{gotoPage(page.page)}}>{page.text}</button></Col>  
        )}
      </Row>
      }
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    companies:state.companies.companies,
    costCenters:state.costCenters.costCenters,
    regions:state.regions.regions,
    employees:state.reports.employees,
    sindicatos:state.sindicatos.sindicatos,
    totalPages:state.reports.totalPages,   
    TotalRecords:state.reports.TotalRecords,
    StartRecord:state.reports.StartRecord, 
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ReporteBody)