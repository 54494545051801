import React, { useState } from 'react';
import { Container, Col, Row, ProgressBar, Dropdown } from 'react-bootstrap';
import { CustomMenu, CustomToggle } from './CustomToggle';

export const UploadStageSelectCostCenter = ({
  OnSelection,
  costCenters,
  ProgressValue,
  ProgressText,
}) => {
  const [CentroCosto, setCentroCosto] = useState('');

  const pickCostCenter = (costCenter) => {
    setCentroCosto(costCenter);
    OnSelection(costCenter);
  };
  return <Container>
    <div style={{ paddingTop: '4rem' }}>
      <p>{ProgressText}</p>
      <ProgressBar now={ProgressValue} />
    </div>
    <div style={{ paddingTop: '4rem', paddingBottom: '1rem' }}>
      <Row>
        <h1>Seleccione Centro de Costo</h1>
      </Row>
    </div>
    <Col>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {costCenters.find(costCenter => costCenter.item === CentroCosto)?.nombre || 'Seleccione'}
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          {costCenters.map((costCenter, index) => {
            if (costCenter.item !== CentroCosto) {
              return <Dropdown.Item key={index} onClick={() => { pickCostCenter(costCenter.item); }}>{costCenter.nombre}</Dropdown.Item>;
            } else {
              return <Dropdown.Item key={index} onClick={() => { pickCostCenter(costCenter.item); }} active>{costCenter.nombre}</Dropdown.Item>;
            }
          })}
        </Dropdown.Menu>
      </Dropdown>
    </Col>
  </Container>;
};
