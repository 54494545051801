import { useState, useEffect } from 'react'
import { Container, Col, Row, Table, Nav, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Navigator from '../../components/Navigator'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { fetchUserDetails, deleteUserAttachment } from '../../store/actions/usersActions'
import { UserDetails } from '../../components/UserDetails'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faCloudDownloadAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { fileNameValidationRegexp } from '../../regexp'
import moment from 'moment'
import Spinner from '../SignInCheck'
import DeleteAttachmentConfirmation from '../../components/DeleteAttachmentConfirmation'

const DetallesPersonalLiquidaciones = ({
  fetchUserDetails,
  deleteUserAttachment,
  details,
  costCenters,
  documents,
  userTypeIsRut,
  canUploadDocuments,
}) =>{
  let query = new URLSearchParams(useLocation().search)
  const [Rut] = useState(query.get("Rut"))
  const [Contrato] = useState(query.get("Contrato"))
  const history = useHistory()
  const [fetchingInProgress,setFetchingInProgress] = useState(true)
  const [uploadToDelete,setUploadToDelete] = useState(null)

  useEffect(()=>{
    fetchUserDetails({
      Rut,
      Contrato,
      OnFetchComplete:(error)=>{
        setFetchingInProgress(false)
      }})
  },[
    Rut,Contrato,
    fetchUserDetails,setFetchingInProgress])

  if(details===null) return null

  return (
    <Container>
      <Navigator/>
      <Row>
        {!userTypeIsRut &&
          <Link to={'/'}>Volver</Link>
        }
      </Row>
      <UserDetails details={details}/>
      <Nav variant="tabs" defaultActiveKey={`/detallesPersonal/liquidaciones?Contrato=${Contrato}&Rut=${Rut}`}>
        <Nav.Item>
          <Nav.Link onClick={()=>{
            history.push(`/detallesPersonal/liquidaciones?Contrato=${Contrato}&Rut=${Rut}`)
          }}>Liquidaciones</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link onClick={()=>{
            history.push(`/detallesPersonal/documentos?Contrato=${Contrato}&Rut=${Rut}`)
          }}>Documentos</Nav.Link>
        </Nav.Item>
      </Nav>
      <Row>
        <Col>
          <h2>Liquidaciones</h2>
        </Col>
        <Col md={{offset:7}} style={{textAlign:'end'}}>
          {!userTypeIsRut && canUploadDocuments &&
          <Button variant="outline-secondary" onClick={()=>{
            history.push(`/upload/liquidaciones?Contrato=${Contrato}&empresa=${details.empresa}&centroCosto=${details.idCentroCosto}&Rut=${Rut}`)
          }}>Subir Liquidacion</Button>
          }
        </Col>
      </Row>
      {fetchingInProgress &&
      <Spinner/>
      }
      {!fetchingInProgress &&
      <Table striped bordered hover size="sm">
        <tbody>
          {details.liquidaciones.map((upload,index)=>{
          let parts = new RegExp(fileNameValidationRegexp).exec(upload.s3Url);
          let datePart = moment(parts[4],"YYYYMMDD")
          return (
            <tr key={index}>
              <td><FontAwesomeIcon icon={faFilePdf} /></td>
              <td>{upload.rexUser}</td>
              <td>{costCenters.find(coc=>coc.item === (''+upload.centroCosto))?.nombre}</td>
              <td>{upload.empleado}</td>
              <td>{details.nombre} {details.apellidoPate} {details.apellidoMate}</td>
              <td>{documents.find(doc=>doc.Codigo === upload.Codigo)?.Descripcion}</td>
              <td>{["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"][datePart.month()]} {datePart.year()}</td>
              <td>Descargar<a style={{paddingLeft:'0.5rem'}} href={`https://docuus-files.s3-sa-east-1.amazonaws.com/${upload.s3Url}`}><FontAwesomeIcon icon={faCloudDownloadAlt}/></a></td>
              <td style={{
                borderRight:'hidden',
                borderTop:'hidden',
                borderBottom:'hidden',
                backgroundColor:'white',
                paddingLeft:'0.5rem'
              }}>{!userTypeIsRut && canUploadDocuments && <FontAwesomeIcon icon={faTimes}  onClick={()=>{
                setUploadToDelete(upload)
              }}/>}</td>
            </tr>
            )}
          )}
        </tbody>
      </Table>
      }
      {uploadToDelete!==null && 
      <DeleteAttachmentConfirmation
        upload={uploadToDelete}
        HideModal={()=>{
          setUploadToDelete(null)
        }}
        OnDelete={()=>{
          setFetchingInProgress(true)
          deleteUserAttachment({
            Contrato,
            Rut:uploadToDelete.empleado,
            s3Url:uploadToDelete.s3Url,
            OnFetchComplete:(error)=>{
              setFetchingInProgress(false)
          }})
          setUploadToDelete(null)
        }}
      />}
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    details:state.userDetails.details,
    costCenters:state.costCenters.costCenters,
    documents:state.documents.documents,
    userTypeIsRut:state.auth.userType==="rut",
    canUploadDocuments:state.auth.canUploadDocuments,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchUserDetails,
  deleteUserAttachment,
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DetallesPersonalLiquidaciones)