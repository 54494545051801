import { API_ENDPOINT } from './APIEndpoint'
import { 
  SET_COST_CENTERS,
  SET_COMPANIES,
  SET_REGIONS,
  SET_SINDICATOS,
  SET_DOCUMENTS,
  SET_CONFIGURACION_USUARIOS,
} from './actionTypes'

export const fetchCompanies = () => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/companies`
  let companies = await (await fetch(query,{
    headers:{
      Authorization:token
    }
  })).json()
  dispatch({
    type:SET_COMPANIES,companies
  })
}

export const fetchCostCenters = () => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/costcenters`
  let costCenters = await (await fetch(query,{
    headers:{
      Authorization:token
    }
  })).json()
  dispatch({
    type:SET_COST_CENTERS,costCenters
  })
}

export const fetchRegions = () => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/regions`
  let regions = await (await fetch(query,{
    headers:{
      Authorization:token
    }
  })).json()
  dispatch({
    type:SET_REGIONS,regions
  })
}

export const fetchSindicatos = () => async (dispatch) => {
  let query = `${API_ENDPOINT}/sindicatos`
  let sindicatos = await (await fetch(query)).json()
  dispatch({
    type:SET_SINDICATOS,sindicatos
  })
}

export const fetchDocuments = () => async (dispatch) => {
  let query = `${API_ENDPOINT}/documents`
  let documents = await (await fetch(query)).json()
  dispatch({
    type:SET_DOCUMENTS,documents
  })
}

export const editDocument = ({Codigo,Descripcion,Obligatorio}) => async (dispatch) => {
  await fetch(`${API_ENDPOINT}/documents/edit`,{
    method:'POST',
    headers: { "Content-Type":"application/json" },
    body: JSON.stringify({Codigo,Descripcion,Obligatorio})
  })
  dispatch(fetchDocuments())
}

export const createDocument = ({Codigo,Descripcion,Obligatorio}) => async (dispatch) => {
  await fetch(`${API_ENDPOINT}/documents/create`,{
    method:'POST',
    headers: { "Content-Type":"application/json" },
    body: JSON.stringify({Codigo,Descripcion,Obligatorio})
  })
  dispatch(fetchDocuments())
}

export const deleteDocument = ({Codigo}) => async (dispatch) => {
  await fetch(`${API_ENDPOINT}/documents/delete`,{
    method:'POST',
    headers: { "Content-Type":"application/json" },
    body: JSON.stringify({Codigo})
  })
  dispatch(fetchDocuments())
}

export const fetchConfiguracionUsuarios = () => async (dispatch) => {
  let query = `${API_ENDPOINT}/configuration/users`
  let usuarios = await (await fetch(query)).json()
  dispatch({type:SET_CONFIGURACION_USUARIOS,usuarios})
}

export const createUsuario = ({Correo,Nombre,Apellido,TipoUsuario,Empresas,Regiones,CentrosCosto,CanUploadDocuments}) => async (dispatch) => {
  await fetch(`${API_ENDPOINT}/configuration/users/create`,{
    method:'POST',
    headers: { "Content-Type":"application/json" },
    body: JSON.stringify({Correo,Nombre,Apellido,TipoUsuario,Empresas,Regiones,CentrosCosto,CanUploadDocuments})
  })
  dispatch(fetchConfiguracionUsuarios())
}

export const deleteUsuario = ({Correo}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth

  try{
    await fetch(`${API_ENDPOINT}/configuration/users/delete`,{
      method:'POST',
      headers: { 
        "Content-Type":"application/json",
        Authorization:token
      },
      body: JSON.stringify({Correo})
    })
  }catch(error){
    console.log(error)
  }
  dispatch(fetchConfiguracionUsuarios())
}

export const editUsuario = ({
  username,
  nombre,
  apellido,
  password,
  empresas,
  regiones,
  centrosCosto,
  CanUploadDocuments,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth

  try{
    await fetch(`${API_ENDPOINT}/configuration/users/update`,{
      method:'POST',
      headers: { 
        "Content-Type":"application/json",
        Authorization:token
      },
      body: JSON.stringify({
        Correo:username,
        Nombre:nombre,
        Apellido:apellido,
        Pass:password,
        CanUploadDocuments,
        Empresas:empresas.length>0?empresas:undefined,
        Regiones:regiones.length>0?regiones:undefined,
        CentrosCosto:centrosCosto.length>0?centrosCosto:undefined
      })
    })
  }catch(error){
    console.log(error)
  }
  dispatch(fetchConfiguracionUsuarios())
}


export const fetchExecutiveUserConfiguration = ({UserName,OnFetchComplete}) => async () => {
  let query = `${API_ENDPOINT}/configuration/executive?UserName=${UserName}`
  OnFetchComplete(await (await fetch(query)).json())
}