import { 
  SET_COST_CENTERS
} from '../actions/actionTypes'

const initialState = {
  costCenters:[],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COST_CENTERS:
      return {
        costCenters: [...action.costCenters]
      };
    default:
      return state;
  }
} 

export default reducer