import React, { useCallback } from 'react';
import { Container, Col, Row, Nav, ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { fileNameValidationRegexp } from '../regexp'

export const UploadStageSelectFiles = ({
  documents,
  acceptedFileList, setAcceptedFileList,
  filesToAccept=null,
  filesToDeny=null,
  ProgressValue,
  ProgressText,
  Rut,
}) => {
  const style = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    borderColor: '#2196f3',
    marginTop: '10px',
    marginBottom: '10px'
  };
  const location = useLocation();
  const filesValidator = (file) => {
    let parts = new RegExp(fileNameValidationRegexp).exec(file.name);
    if (parts === null || parts.length !== 6 || documents.find(doc => doc.Codigo === parts[1]) === undefined) {
      return { code: 'invalid-file', message: 'nombre de archivo invalido' };
    }
    if(Rut!==null && Rut !== `${parts[2]}-${parts[3]}`){
      return { code: 'invalid-file', message: 'Rut de archivo no permitido' };
    }
    if(filesToDeny!==null && filesToDeny.includes(parts[1])) {
      return { code: 'invalid-file', message: 'tipo de archivo no permitido' };
    }
    if(filesToAccept!==null && !filesToAccept.includes(parts[1])) {
      return { code: 'invalid-file', message: 'tipo de archivo no permitido' };
    }
    return null;
  };

  const onDrop = useCallback(acceptedFiles => {
    setAcceptedFileList([...acceptedFileList, ...acceptedFiles].filter(
      (file, index, array) => {
        return index === array.findIndex(fileInArray => fileInArray.path === file.path);
      }));
  }, [acceptedFileList, setAcceptedFileList]);

  const {
    //acceptedFiles,
    fileRejections, getRootProps, getInputProps } = useDropzone({
      validator: filesValidator,
      onDrop: onDrop
    });

  const filesAccepted = acceptedFileList.map((file, index) => {
    let parts = new RegExp(fileNameValidationRegexp).exec(file.name);
    return (
      <li key={index}>
        <Row>
          <Col>
            <FontAwesomeIcon icon={faFilePdf} />
          </Col>
          <Col>
            {parts[1]} - {documents.find(doc => doc.Codigo === parts[1]).Descripcion} - {parts[2]}-{parts[3]}
          </Col>
          <Col>
            <FontAwesomeIcon icon={faCheckCircle} color="green"/>
          </Col>
        </Row>
        {/* - {file.size} bytes */}
      </li>
    );
  }
  );

  const filesRejected = fileRejections.map(({ file, errors }, index) => (
    <li key={index} title={errors[0].message}>
      <Row>
        <Col>
          <FontAwesomeIcon icon={faFilePdf} />
        </Col>
        <Col>
          {file.path}
        </Col>
        <Col>
          <FontAwesomeIcon icon={faTimesCircle} color="red"/>
        </Col>
      </Row>
      {/* - {file.size} bytes {errors.message}*/}
    </li>
  ));

  return (
    <Container>
      <div style={{ paddingTop: '4rem' }}>
        <p>{ProgressText}</p>
        <ProgressBar now={ProgressValue} />
      </div>
      <div style={{ paddingTop: '4rem', paddingBottom: '1rem' }}>
        <Row>
          <h1>Arrastre Documentos</h1>
        </Row>
      </div>
      <section className="container">
        <div {...getRootProps({ className: 'dropzone', multiple: true, style })}>
          <input {...getInputProps()} />
          <p>Arrastre aqui</p>
        </div>
      </section>
      <Nav variant="tabs" defaultActiveKey={`#/accepted`}>
        <Nav.Item>
          <Nav.Link href={`#/accepted`}>({acceptedFileList.length}) Documentos Correctos</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href={`#/rejected`}>({fileRejections.length}) Documentos Descartados</Nav.Link>
        </Nav.Item>
      </Nav>
      {[``, `#/accepted`].includes(location.hash) &&
        <aside>
          <ul className="stage-docs">{filesAccepted}</ul>
        </aside>}
      {[`#/rejected`].includes(location.hash) &&
        <aside>
          <h4>Documentos Descartados con errores de nomenclatura</h4>
          <ul className="stage-docs">{filesRejected}</ul>
        </aside>}
    </Container>
  );
};
