import { API_ENDPOINT } from './APIEndpoint'
import {
  SET_REPORTS_SUMMARY,
  SET_REPORTS_USERS,
  RESET_REPORTS_USER,
  SET_REPORTE_DOCUMENTOS_PERSONALIZADO_FILTERS,
  SET_REPORTE_LIQUIDACIONES_PERSONALIZADO_FILTERS
} from './actionTypes'
import { signOut } from './authActions'

export const fetchReportsSummary = ({
  Empresas,
  OnLoadingComplete,
  OnLoadingError
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/reports/summary`
  let params = []
  if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
  query = `${query}?${params.join('&')}`
  try{
    let response = await fetch(query,{headers:{Authorization:token}})
    if(response.status===200){
      let summary = await response.json()
      dispatch({type:SET_REPORTS_SUMMARY,...summary})
    } else if(response.status===403){
      OnLoadingError({error:"No se pudo obtener"})
      return
    } else if(response.status===503){
      OnLoadingError({error:"No se pudo obtener"})
      return
    }
  } catch(error){
    OnLoadingError({error:"No se pudo obtener"})
    return
  }
  OnLoadingComplete()
}

export const resetReportsUsers = () => async (dispatch) =>{
  dispatch({type:RESET_REPORTS_USER})
}

export const fetchActivesWithoutContract = ({
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
  PageNumber,
  PageSize,
  history,
  OnFetchComplete,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/reports/active-without-contract`
  let params = []
  if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
  if(CentrosCosto.length>0) params.push(`CentrosCosto=${CentrosCosto.join(',')}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if(Regiones.length>0) params.push(`Regiones=${Regiones.join(',')}`)
  if(Sindicatos.length>0) params.push(`Sindicatos=${Sindicatos.join(',')}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if(Estados.length>0) params.push(`Estados=${Estados.join(',')}`)
  if((PageNumber || '')!=='') params.push(`PageNumber=${PageNumber}`)
  if((PageSize || '')!=='') params.push(`PageSize=${PageSize}`)
  query = `${query}?${params.join('&')}`
  try{
    let response = await fetch(
      query,{
        headers:{
          Authorization:token
        }
      }
    )
    if(response.status===200){
      let users = await response.json()
      dispatch({
        type:SET_REPORTS_USERS,
        employees:users.employees,
        totalPages:users.totalPages,
        TotalRecords: users.TotalRecords,
        StartRecord: users.StartRecord,
        currentPage:1,
      })
      OnFetchComplete()
    } else if(response.status===403){
      OnFetchComplete()
      localStorage.removeItem("docuus")
      dispatch(signOut())
      history.replace("/")
    }
  } catch(error){
    console.log(error)
    OnFetchComplete(error)
  }
}

export const fetchTerminatedWorkersWithoutTerminationDocument = ({
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
  PageNumber,
  PageSize,
  history,
  OnFetchComplete,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/reports/terminated-workers-without-termination-document`
  let params = []
  if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
  if(CentrosCosto.length>0) params.push(`CentrosCosto=${CentrosCosto.join(',')}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if(Regiones.length>0) params.push(`Regiones=${Regiones.join(',')}`)
  if(Sindicatos.length>0) params.push(`Sindicatos=${Sindicatos.join(',')}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if(Estados.length>0) params.push(`Estados=${Estados.join(',')}`)
  if((PageNumber || '')!=='') params.push(`PageNumber=${PageNumber}`)
  if((PageSize || '')!=='') params.push(`PageSize=${PageSize}`)
  query = `${query}?${params.join('&')}`
  try{
    let response = await fetch(
      query,{
        headers:{
          Authorization:token
        }
      }
    )
    if(response.status===200){
      let users = await response.json()
      dispatch({
        type:SET_REPORTS_USERS,
        employees:users.employees,
        totalPages:users.totalPages,
        TotalRecords: users.TotalRecords,
        StartRecord: users.StartRecord,
        currentPage:1,
      })
      OnFetchComplete()
    } else if(response.status===403){
      OnFetchComplete()
      localStorage.removeItem("docuus")
      dispatch(signOut())
      history.replace("/")
    }
  } catch(error){
    console.log(error)
    OnFetchComplete(error)
  }
}

export const fetchWorkersWithoutAnyDocument = ({
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
  PageNumber,
  PageSize,
  history,
  OnFetchComplete,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/reports/workers-without-any-document`
  let params = []
  if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
  if(CentrosCosto.length>0) params.push(`CentrosCosto=${CentrosCosto.join(',')}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if(Regiones.length>0) params.push(`Regiones=${Regiones.join(',')}`)
  if(Sindicatos.length>0) params.push(`Sindicatos=${Sindicatos.join(',')}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if(Estados.length>0) params.push(`Estados=${Estados.join(',')}`)
  if((PageNumber || '')!=='') params.push(`PageNumber=${PageNumber}`)
  if((PageSize || '')!=='') params.push(`PageSize=${PageSize}`)
  query = `${query}?${params.join('&')}`
  try{
    let response = await fetch(
      query,{
        headers:{
          Authorization:token
        }
      }
    )
    if(response.status===200){
      let users = await response.json()
      dispatch({
        type:SET_REPORTS_USERS,
        employees:users.employees,
        totalPages:users.totalPages,
        TotalRecords: users.TotalRecords,
        StartRecord: users.StartRecord,
        currentPage:1,
      })
      OnFetchComplete()
    } else if(response.status===403){
      OnFetchComplete()
      localStorage.removeItem("docuus")
      dispatch(signOut())
      history.replace("/")
    }
  } catch(error){
    console.log(error)
    OnFetchComplete(error)
  }
}

export const fetchWorkersWithLessThan6MonthlyPaymentDocuments = ({
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
  PageNumber,
  PageSize,
  history,
  OnFetchComplete,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/reports/workers-with-less-than-6-monthly-payment-documents`
  let params = []
  if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
  if(CentrosCosto.length>0) params.push(`CentrosCosto=${CentrosCosto.join(',')}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if(Regiones.length>0) params.push(`Regiones=${Regiones.join(',')}`)
  if(Sindicatos.length>0) params.push(`Sindicatos=${Sindicatos.join(',')}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if(Estados.length>0) params.push(`Estados=${Estados.join(',')}`)
  if((PageNumber || '')!=='') params.push(`PageNumber=${PageNumber}`)
  if((PageSize || '')!=='') params.push(`PageSize=${PageSize}`)
  query = `${query}?${params.join('&')}`
  try{
    let response = await fetch(
      query,{
        headers:{
          Authorization:token
        }
      }
    )
    if(response.status===200){
      let users = await response.json()
      dispatch({
        type:SET_REPORTS_USERS,
        employees:users.employees,
        totalPages:users.totalPages,
        TotalRecords: users.TotalRecords,
        StartRecord: users.StartRecord,
        currentPage:1,
      })
      OnFetchComplete()
    } else if(response.status===403){
      OnFetchComplete()
      localStorage.removeItem("docuus")
      dispatch(signOut())
      history.replace("/")
    }
  } catch(error){
    console.log(error)
    OnFetchComplete(error)
  }
}

export const fetchWorkersWithoutLastMonthlyPaymentDocuments = ({
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
  PageNumber,
  PageSize,
  history,
  OnFetchComplete,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/reports/workers-without-last-monthly-payment-documents`
  let params = []
  if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
  if(CentrosCosto.length>0) params.push(`CentrosCosto=${CentrosCosto.join(',')}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if(Regiones.length>0) params.push(`Regiones=${Regiones.join(',')}`)
  if(Sindicatos.length>0) params.push(`Sindicatos=${Sindicatos.join(',')}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if(Estados.length>0) params.push(`Estados=${Estados.join(',')}`)
  if((PageNumber || '')!=='') params.push(`PageNumber=${PageNumber}`)
  if((PageSize || '')!=='') params.push(`PageSize=${PageSize}`)
  query = `${query}?${params.join('&')}`
  try{
    let response = await fetch(
      query,{
        headers:{
          Authorization:token
        }
      }
    )
    if(response.status===200){
      let users = await response.json()
      dispatch({
        type:SET_REPORTS_USERS,
        employees:users.employees,
        totalPages:users.totalPages,
        TotalRecords: users.TotalRecords,
        StartRecord: users.StartRecord,
        currentPage:1,
      })
      OnFetchComplete()
    } else if(response.status===403){
      OnFetchComplete()
      localStorage.removeItem("docuus")
      dispatch(signOut())
      history.replace("/")
    }
  } catch(error){
    console.log(error)
    OnFetchComplete(error)
  }
}

export const fetchWorkersWithoutMonthlyPaymentDocument = ({
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
  PageNumber,
  PageSize,
  history,
  OnFetchComplete,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/reports/workers-without-monthly-payment-document`
  let params = []
  if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
  if(CentrosCosto.length>0) params.push(`CentrosCosto=${CentrosCosto.join(',')}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if(Regiones.length>0) params.push(`Regiones=${Regiones.join(',')}`)
  if(Sindicatos.length>0) params.push(`Sindicatos=${Sindicatos.join(',')}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if(Estados.length>0) params.push(`Estados=${Estados.join(',')}`)
  if((PageNumber || '')!=='') params.push(`PageNumber=${PageNumber}`)
  if((PageSize || '')!=='') params.push(`PageSize=${PageSize}`)
  query = `${query}?${params.join('&')}`
  try{
    let response = await fetch(
      query,{
        headers:{
          Authorization:token
        }
      }
    )
    if(response.status===200){
      let users = await response.json()
      dispatch({
        type:SET_REPORTS_USERS,
        employees:users.employees,
        totalPages:users.totalPages,
        TotalRecords: users.TotalRecords,
        StartRecord: users.StartRecord,
        currentPage:1,
      })
      OnFetchComplete()
    } else if(response.status===403){
      OnFetchComplete()
      localStorage.removeItem("docuus")
      dispatch(signOut())
      history.replace("/")
    }
  } catch(error){
    console.log(error)
    OnFetchComplete(error)
  }
}

export const fetchReportsExportUrl = ({
  Report,
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/reports/export`
  let params = []
  if((Report || '')!=='') params.push(`Report=${Report}`)
  if((Empresas || '')!=='') params.push(`Empresas=${Empresas}`)
  if((CentrosCosto || '')!=='') params.push(`CentrosCosto=${CentrosCosto}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if((Regiones || '')!=='') params.push(`Regiones=${Regiones}`)
  if((Sindicatos || '')!=='') params.push(`Sindicatos=${Sindicatos}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if((Estados || '')!=='') params.push(`Estados=${Estados}`)
  query = `${query}?${params.join('&')}`
  try{
    let url = await (await fetch(query,{
      headers:{
        Authorization:token
      }
    })).text()
    window.open(url)
  } catch(error){
    console.log(error)
  }
}

export const setFiltrosReporteDocumentosPersonalizado = ({
  Estado,
  Condicion,
  Documentos,
  Empresas,
  CentrosCosto,
  Regiones,
  Sindicatos,
  FechaDocumento,
  FechaDocumento2
}) => (dispatch) => {
  dispatch({
    type:SET_REPORTE_DOCUMENTOS_PERSONALIZADO_FILTERS,
    Estado,
    Condicion,
    Documentos,
    Empresas,
    CentrosCosto,
    Regiones,
    Sindicatos,
    FechaDocumento,
    FechaDocumento2
  })
}

export const fetchReporteDocumentosPersonalizado = ({
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  FechaDocumento,
  FechaDocumento2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
  Condicion,
  Documentos,
  PageNumber,
  PageSize,
  history,
  OnFetchComplete,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth

  let query = `${API_ENDPOINT}/reports/custom-documents-report`
  let params = []
  if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
  if(CentrosCosto.length>0) params.push(`CentrosCosto=${CentrosCosto.join(',')}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if((FechaDocumento || '')!=='') params.push(`FechaDocumento=${FechaDocumento}`)
  if((FechaDocumento2 || '')!=='') params.push(`FechaDocumento2=${FechaDocumento2}`)
  if(Regiones.length>0) params.push(`Regiones=${Regiones.join(',')}`)
  if(Sindicatos.length>0) params.push(`Sindicatos=${Sindicatos.join(',')}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if(Estados.length>0) params.push(`Estados=${Estados.join(',')}`)
  /*if((Condicion || '')!=='') */params.push(`Condicion=${Condicion}`)
  if(Documentos.length>0) params.push(`Documentos=${Documentos.join(',')}`)
  if((PageNumber || '')!=='') params.push(`PageNumber=${PageNumber}`)
  if((PageSize || '')!=='') params.push(`PageSize=${PageSize}`)
  query = `${query}?${params.join('&')}`
  try{
    let response = await fetch(
      query,{
        headers:{
          Authorization:token
        }
      }
    )
    if(response.status===200){
      let users = await response.json()
      dispatch({
        type:SET_REPORTS_USERS,
        employees:users.employees,
        totalPages:users.totalPages,
        TotalRecords: users.TotalRecords,
        StartRecord: users.StartRecord,
        currentPage:1,
      })
      OnFetchComplete()
    } else if(response.status===403){
      OnFetchComplete()
      localStorage.removeItem("docuus")
      dispatch(signOut())
      history.replace("/")
    }
  } catch(error){
    console.log(error)
    OnFetchComplete(error)
  }
}

export const fetchReportsCustomDocumentsExportUrl = ({
  Report,
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  FechaDocumento,
  FechaDocumento2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
  Condicion,
  Documentos,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/reports/custom-documents-export`
  let params = []
  if((Report || '')!=='') params.push(`Report=${Report}`)
  if((Empresas || '')!=='') params.push(`Empresas=${Empresas}`)
  if((CentrosCosto || '')!=='') params.push(`CentrosCosto=${CentrosCosto}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if((FechaDocumento || '')!=='') params.push(`FechaDocumento=${FechaDocumento}`)
  if((FechaDocumento2 || '')!=='') params.push(`FechaDocumento2=${FechaDocumento2}`)
  if((Regiones || '')!=='') params.push(`Regiones=${Regiones}`)
  if((Sindicatos || '')!=='') params.push(`Sindicatos=${Sindicatos}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if((Estados || '')!=='') params.push(`Estados=${Estados}`)
  /*if((Condicion || '')!=='') */params.push(`Condicion=${Condicion}`)
  if((Documentos || '')!=='') params.push(`Documentos=${Documentos}`)
  query = `${query}?${params.join('&')}`
  try{
    let url = await (await fetch(query,{
      headers:{
        Authorization:token
      }
    })).text()
    window.open(url)
  } catch(error){
    console.log(error)
  }
}

export const setFiltrosReporteLiquidacionesPersonalizado = ({
  Estado,
  Condicion,
  NumeroLiquidaciones,
  Empresas,
  CentrosCosto,
  Regiones,
  Sindicatos,
  FechaTerminoSeleccionadoMonth,
  FechaTerminoSeleccionadoYear,
}) => (dispatch) => {
  dispatch({
    type:SET_REPORTE_LIQUIDACIONES_PERSONALIZADO_FILTERS,
    Estado,
    Condicion,
    NumeroLiquidaciones,
    Empresas,
    CentrosCosto,
    Regiones,
    Sindicatos,
    FechaTerminoSeleccionadoMonth,
    FechaTerminoSeleccionadoYear,
  })
}

export const fetchReporteLiquidacionesPersonalizado = ({
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
  FechaTerminoSeleccionadoMonth,
  FechaTerminoSeleccionadoYear,
  Condicion,
  NumeroLiquidaciones,
  PageNumber,
  PageSize,
  history,
  OnFetchComplete,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth

  let query = `${API_ENDPOINT}/reports/custom-liquidaciones-report`
  let params = []
  if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
  if(CentrosCosto.length>0) params.push(`CentrosCosto=${CentrosCosto.join(',')}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if(Regiones.length>0) params.push(`Regiones=${Regiones.join(',')}`)
  if(Sindicatos.length>0) params.push(`Sindicatos=${Sindicatos.join(',')}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if(Estados.length>0) params.push(`Estados=${Estados.join(',')}`)
  if(FechaTerminoSeleccionadoMonth!==null){
    params.push(`FechaTerminoSeleccionadoMonth=${FechaTerminoSeleccionadoMonth}`)
  }
  if(FechaTerminoSeleccionadoYear!==null){
    params.push(`FechaTerminoSeleccionadoYear=${FechaTerminoSeleccionadoYear}`)
  }
  /*if((Condicion || '')!=='') */params.push(`Condicion=${Condicion}`)
  /*if(Documentos.length>0) */params.push(`NumeroLiquidaciones=${NumeroLiquidaciones}`)
  if((PageNumber || '')!=='') params.push(`PageNumber=${PageNumber}`)
  if((PageSize || '')!=='') params.push(`PageSize=${PageSize}`)
  query = `${query}?${params.join('&')}`
  try{
    let response = await fetch(
      query,{
        headers:{
          Authorization:token
        }
      }
    )
    if(response.status===200){
      let users = await response.json()
      dispatch({
        type:SET_REPORTS_USERS,
        employees:users.employees,
        totalPages:users.totalPages,
        TotalRecords: users.TotalRecords,
        StartRecord: users.StartRecord,
        currentPage:1,
      })
      OnFetchComplete()
    } else if(response.status===403){
      OnFetchComplete()
      localStorage.removeItem("docuus")
      dispatch(signOut())
      history.replace("/")
    }
  } catch(error){
    console.log(error)
    OnFetchComplete(error)
  }
}

export const fetchReportsCustomLiquidacionesExportUrl = ({
  Report,
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
  FechaTerminoSeleccionadoMonth,
  FechaTerminoSeleccionadoYear,
  Condicion,
  NumeroLiquidaciones,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/reports/custom-liquidaciones-export`
  let params = []
  if((Report || '')!=='') params.push(`Report=${Report}`)
  if((Empresas || '')!=='') params.push(`Empresas=${Empresas}`)
  if((CentrosCosto || '')!=='') params.push(`CentrosCosto=${CentrosCosto}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if((Regiones || '')!=='') params.push(`Regiones=${Regiones}`)
  if((Sindicatos || '')!=='') params.push(`Sindicatos=${Sindicatos}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if((Estados || '')!=='') params.push(`Estados=${Estados}`)
  if(FechaTerminoSeleccionadoMonth!==null){
    params.push(`FechaTerminoSeleccionadoMonth=${FechaTerminoSeleccionadoMonth}`)
  }
  if(FechaTerminoSeleccionadoYear!==null){
    params.push(`FechaTerminoSeleccionadoYear=${FechaTerminoSeleccionadoYear}`)
  }
  /*if((Condicion || '')!=='') */params.push(`Condicion=${Condicion}`)
  /*if((Documentos || '')!=='') */params.push(`NumeroLiquidaciones=${NumeroLiquidaciones}`)
  query = `${query}?${params.join('&')}`
  try{
    let url = await (await fetch(query,{
      headers:{
        Authorization:token
      }
    })).text()
    window.open(url)
  } catch(error){
    console.log(error)
  }
}

export const fetchWorkersWithoutVacationsDocument = ({
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
  PageNumber,
  PageSize,
  history,
  OnFetchComplete,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/reports/workers-without-vacations-document`
  let params = []
  if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
  if(CentrosCosto.length>0) params.push(`CentrosCosto=${CentrosCosto.join(',')}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if(Regiones.length>0) params.push(`Regiones=${Regiones.join(',')}`)
  if(Sindicatos.length>0) params.push(`Sindicatos=${Sindicatos.join(',')}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if(Estados.length>0) params.push(`Estados=${Estados.join(',')}`)
  if((PageNumber || '')!=='') params.push(`PageNumber=${PageNumber}`)
  if((PageSize || '')!=='') params.push(`PageSize=${PageSize}`)
  query = `${query}?${params.join('&')}`
  try{
    let response = await fetch(
      query,{
        headers:{
          Authorization:token
        }
      }
    )
    if(response.status===200){
      let users = await response.json()
      dispatch({
        type:SET_REPORTS_USERS,
        employees:users.employees,
        totalPages:users.totalPages,
        TotalRecords: users.TotalRecords,
        StartRecord: users.StartRecord,
        currentPage:1,
      })
      OnFetchComplete()
    } else if(response.status===403){
      OnFetchComplete()
      localStorage.removeItem("docuus")
      dispatch(signOut())
      history.replace("/")
    }
  } catch(error){
    console.log(error)
    OnFetchComplete(error)
  }
}