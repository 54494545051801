import React from 'react';
import { Container, Col, Row, InputGroup, ProgressBar } from 'react-bootstrap';

export const UploadStageSelectCompany = ({
  OnSelection,
  Companies,
  ProgressValue,
  ProgressText,
}) => {
  const pickCompany = (company) => {
    OnSelection(company);
  };
  return <Container>
    <div style={{ paddingTop: '4rem' }}>
      <p>{ProgressText}</p>
      <ProgressBar now={ProgressValue} />
    </div>
    <div style={{ paddingTop: '4rem', paddingBottom: '1rem' }}>
      <Row>
        <h1>Seleccione Empresa</h1>
      </Row>
    </div>
    <Col className="company-select">
      {Companies.map((company, index) => {
        return (
          <InputGroup key={index}>
            <InputGroup.Prepend>
              <InputGroup.Radio name="company" onClick={() => { pickCompany(company); }} />
              {company}
            </InputGroup.Prepend>
          </InputGroup>
        );
      })}
    </Col>
  </Container>;
};
