import { Col, Form } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'

export const CustomFechaIngresoRangePicker = ({title='Fecha de Ingreso',onApply,onCancel})=>
<Col className="form-label">
  <Form.Label style={{marginBottom:'0'}}><strong>{title}</strong></Form.Label>
  <DateRangePicker
    initialSettings={{
      showDropdowns: true,
      autoUpdateInput: false,
      locale: {
        cancelLabel: 'Limpiar',
        applyLabel:'Aplicar',
        monthNames:["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
        format:"DD/MM/YYYY",
      },
    }}
    onApply={onApply}
    onCancel={onCancel}
  >
    <input type="text" className="form-control" defaultValue="" />
  </DateRangePicker>
</Col>