import React, { useState } from 'react'
import { Container, Row, Col, Button, Dropdown, InputGroup } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import Navigator from '../../components/Navigator'
import { CustomToggle, CustomMenu } from '../../components/CustomToggle'
import { CustomFechaIngresoRangePicker } from '../../components/CustomFechaIngresoRangePicker'
import {
  setFiltrosReporteDocumentosPersonalizado
} from '../../store/actions/reportsActions'

const ReportesDocumentos = ({
  documents,
  companies,
  costCenters,
  regions,
  sindicatos,
  setFiltrosReporteDocumentosPersonalizado
}) => {
  const history = useHistory()

  const [Estado,SetEstado] = useState(null)
  const [Condicion,SetCondicion] = useState(null)
  const [Documentos,SetDocumentos] = useState([])
  const [Empresas,setEmpresas] = useState([])
  const [CentrosCosto,setCentrosCosto] = useState([])
  const [Regiones,setRegiones] = useState([])
  const [Sindicatos,setSindicatos] = useState([])
  const [FechaDocumento ,setFechaDocumento] = useState('')
  const [FechaDocumento2,setFechaDocumento2] = useState('')

  let toggleDocumentos = (codigoToToggle)=>{
    let codigoIndex = Documentos.indexOf(codigoToToggle)
    if(codigoIndex===-1){
      SetDocumentos([...Documentos,codigoToToggle])
    } else {
      SetDocumentos([...Documentos].filter((_,index)=>index!==codigoIndex))
    }
  }

  let toggleEmpresas = (empresaToToggle)=>{
    let empIndex = Empresas.indexOf(empresaToToggle)
    if(empIndex===-1){
      setEmpresas([...Empresas,empresaToToggle])
    } else {
      setEmpresas([...Empresas].filter((_,index)=>index!==empIndex))
    }
  }

  let toggleCentrosDeCostos = (costCenterToToggle)=>{
    let costCenterIndex = CentrosCosto.indexOf(costCenterToToggle)
    if(costCenterIndex===-1){
      setCentrosCosto([...CentrosCosto,costCenterToToggle])
    } else {
      setCentrosCosto([...CentrosCosto].filter((_,index)=>index!==costCenterIndex))
    }
  }

  let toggleRegiones = (regionToToggle)=>{
    let regionIndex = Regiones.indexOf(regionToToggle)
    if(regionIndex===-1){
      setRegiones([...Regiones,regionToToggle])
    } else {
      setRegiones([...Regiones].filter((_,index)=>index!==regionIndex))
    }
  }

  let toggleSindicatos = (sindicatoToToggle)=>{
    let sindicatoIndex = Sindicatos.indexOf(sindicatoToToggle)
    if(sindicatoIndex===-1){
      setSindicatos([...Sindicatos,sindicatoToToggle])
    } else {
      setSindicatos([...Sindicatos].filter((_,index)=>index!==sindicatoIndex))
    }
  }

  let EmpresasTitle = ''
  if(Empresas.length===0) { EmpresasTitle = 'Todas' }
  if(Empresas.length===1) { EmpresasTitle =  Empresas[0]}
  if(Empresas.length > 1) { EmpresasTitle = `${Empresas.length} Seleccionadas` }

  let CentrosCostoTitle = ''
  if(CentrosCosto.length===0) { CentrosCostoTitle = 'Todos' }
  if(CentrosCosto.length===1) { CentrosCostoTitle = costCenters.find(costCenter => costCenter.item === CentrosCosto[0])?.nombre || `Centro Costo ${CentrosCosto[0]}` }
  if(CentrosCosto.length > 1) { CentrosCostoTitle = `${CentrosCosto.length} Seleccionados` }

  let RegionesTitle = ''
  if(Regiones.length===0) { RegionesTitle = 'Todas' }
  if(Regiones.length===1) { RegionesTitle = regions.find(region=>region.id === Regiones[0])?.nombre }
  if(Regiones.length > 1) { RegionesTitle = `${Regiones.length} Seleccionadas` }

  let SindicatosTitle = ''
  if(Sindicatos.length===0) { SindicatosTitle = 'Todos' }
  if(Sindicatos.length===1) { SindicatosTitle = Sindicatos[0]}
  if(Sindicatos.length > 1) { SindicatosTitle = `${Sindicatos.length} Seleccionados` }

  let DocumentosTitle = ''
  if(Documentos.length===0) { DocumentosTitle = 'Todos' }
  if(Documentos.length===1) { 
    let docSeleccionado = documents.find(doc=>doc.Codigo === Documentos[0])
    DocumentosTitle = `${docSeleccionado.Codigo} ${docSeleccionado.Descripcion}`
  }
  if(Documentos.length > 1) { DocumentosTitle = `${Documentos.length} Seleccionados` }
  let FiltroRangoFechaTitle = 'Rango Fecha Documentos / Ingreso'
  if(Condicion!==null && Condicion===true) { FiltroRangoFechaTitle = 'Rango Fecha Documentos' }
  if(Condicion!==null && Condicion===false) { FiltroRangoFechaTitle = 'Rango Fecha Ingreso' }
  let generarReporteActivo = (Estado!==null && Condicion!==null)

  const gotoResultado = () => {
    setFiltrosReporteDocumentosPersonalizado({
      Estado,
      Condicion,
      Documentos,
      Empresas,
      CentrosCosto,
      Regiones,
      Sindicatos,
      FechaDocumento,
      FechaDocumento2
    })
    history.push('/reports/documentos/result')
  }

  const fechaDocumentoApply = (event, picker) => {
    setFechaDocumento(picker.startDate.format('YYYYMMDD'))
    setFechaDocumento2(picker.endDate.format('YYYYMMDD'))
    picker.element.val(
      picker.startDate.format('DD/MM/YYYY') +
        ' - ' +
        picker.endDate.format('DD/MM/YYYY')
    )
  }

  const fechaDocumentoCancel = (event, picker) => {
    setFechaDocumento('')
    setFechaDocumento2('')
    picker.element.val('');
  }

  return (
    <Container>
      <Navigator/>
      <Row style={{marginTop:'3rem'}}>
        <Col><h2>Reportes Personalizado de Documentos</h2></Col>
      </Row>
      <Row>
        <Col xs={4}>Paso 1</Col>
        <Col xs={4}>Paso 2</Col>
        <Col xs={4}>Paso 3</Col>
      </Row>
      <Row>
        <Col xs={4}>Tipo Trabajador</Col>
        <Col xs={4}>Condición</Col>
        <Col xs={4}>Seleccion de Documentos</Col>
      </Row>
      <Row>
        <Col xs={4} className="tipo-trabajador-select">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-trabajador" onClick={() => { SetEstado("Todos") }} />
              Todos
            </InputGroup.Prepend>
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-trabajador" onClick={() => { SetEstado("Activo") }} />
              Activo
            </InputGroup.Prepend>
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-trabajador" onClick={() => { SetEstado("Pendiente") }} />
              Pendiente
            </InputGroup.Prepend>
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-trabajador" onClick={() => { SetEstado("Terminado") }} />
              Terminado
            </InputGroup.Prepend>
          </InputGroup>
        </Col>
        <Col xs={4} className="tipo-documento-select">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-documento" onClick={() => { SetCondicion(false) }} />
              NO Tenga los Documentos
            </InputGroup.Prepend>
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Radio name="tipo-documento" onClick={() => { SetCondicion(true) }} />
              SI tenga los Documentos
            </InputGroup.Prepend>
          </InputGroup>
        </Col>
        <Col xs={4} className="form-label">
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {DocumentosTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                Documentos.length < documents.length &&
                <Dropdown.Item onClick={() => { 
                    SetDocumentos(documents.map(({Codigo})=>Codigo))
                }}>Todos</Dropdown.Item>  
              }
              {
                Documentos.length === documents.length &&
                <Dropdown.Item onClick={() => { SetDocumentos([]) }}>Ninguna</Dropdown.Item>
              }
              {documents.map((document,index)=>{
                let docTitle = `${document.Codigo} ${document.Descripcion}`
                return (
                <Dropdown.Item key={index} onClick={() => { 
                  toggleDocumentos(document.Codigo)
                 }} active={(Documentos.indexOf(document.Codigo)!==-1)}>{docTitle}</Dropdown.Item>
                )}
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row style={{marginTop:'1rem'}}>
        <Col xs={4}>Paso 4</Col>
      </Row>
      <Row>
        <Col><h2>Filtros Generales</h2></Col>
      </Row>
      <Row>
        <Col className="form-label">
          <strong>Empresa</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {EmpresasTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                Empresas.length < companies.length &&
                <Dropdown.Item onClick={() => { setEmpresas(companies) }}>Todas</Dropdown.Item>  
              }
              {
                Empresas.length === companies.length &&
                <Dropdown.Item onClick={() => { setEmpresas([]) }}>Ninguna</Dropdown.Item>
              }
              {companies.map((company,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleEmpresas(company) }} active={(Empresas.indexOf(company)!==-1)}>{company}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="form-label">
          <strong>Centro de Costo</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {CentrosCostoTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                CentrosCosto.length < costCenters.length &&
                <Dropdown.Item onClick={() => { setCentrosCosto(costCenters.map(({item})=>item)) }}>Todas</Dropdown.Item>  
              }
              {
                CentrosCosto.length === costCenters.length &&
                <Dropdown.Item onClick={() => { setCentrosCosto([]) }}>Ninguno</Dropdown.Item>
              }
              {costCenters.map((costCenter,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleCentrosDeCostos(costCenter.item) }} active={(CentrosCosto.indexOf(costCenter.item)!==-1)}>{costCenter.nombre}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="form-label">
          <strong>Región</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {RegionesTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                Regiones.length < regions.length &&
                <Dropdown.Item onClick={() => { setRegiones(regions.map(({id})=>id)) }}>Todas</Dropdown.Item>  
              }
              {
                Regiones.length === regions.length &&
                <Dropdown.Item onClick={() => { setRegiones([]) }}>Ninguna</Dropdown.Item>
              }
              {regions.map((region,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleRegiones(region.id) }} active={(Regiones.indexOf(region.id)!==-1)}>{region.nombre}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <CustomFechaIngresoRangePicker 
          title={FiltroRangoFechaTitle}
          onApply={fechaDocumentoApply}
          onCancel={fechaDocumentoCancel}
        />
        <Col className="form-label">
          <strong>Sindicato</strong>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {SindicatosTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                Sindicatos.length < sindicatos.length &&
                <Dropdown.Item onClick={() => { setSindicatos(sindicatos.map(({sindicato})=>sindicato)) }}>Todos</Dropdown.Item>  
              }
              {
                Sindicatos.length === sindicatos.length &&
                <Dropdown.Item onClick={() => { setSindicatos([]) }}>Ninguna</Dropdown.Item>
              }
              {sindicatos.map((sindicato,index)=>
                <Dropdown.Item key={index} onClick={() => { toggleSindicatos(sindicato.sindicato) }} active={(Sindicatos.indexOf(sindicato.sindicato)!==-1)}>{sindicato.sindicato}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row style={{marginTop:'3rem'}}>
        <Button variant="outline-secondary" onClick={gotoResultado} active={generarReporteActivo} disabled={!generarReporteActivo}>Generar Reporte</Button>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => {
  return { 
    documents:state.documents.documents.filter(({Codigo})=>Codigo!=='LS'),
    companies:state.companies.companies,
    costCenters:state.costCenters.costCenters,
    regions:state.regions.regions,
    sindicatos:state.sindicatos.sindicatos,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  setFiltrosReporteDocumentosPersonalizado
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ReportesDocumentos)