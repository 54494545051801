import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Button } from 'react-bootstrap'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Navigator from '../../components/Navigator'
import { useHistory, useLocation } from 'react-router-dom'
import { UploadStageSelectCompany } from '../../components/UploadStageSelectCompany';
import { UploadStageSelectFiles } from '../../components/UploadStageSelectFiles';
import { UploadStageUploadFiles } from '../../components/UploadStageUploadFiles'

const UploadDocumentos = ({
  companies,
  costCenters,
  documents,
}) => {
  let query = new URLSearchParams(useLocation().search)

  const [enablePrevButton,setEnablePrevButton] = useState(true)
  const [empresa] = useState(query.get("empresa"))
  const [centroCosto] = useState(query.get("centroCosto"))
  const [Rut] = useState(query.get("Rut"))
  const [Contrato] = useState(query.get("Contrato"))

  const [uploadStage,setUploadStage] = useState(1)
  const [companySelected,setCompanySelected] = useState('')
  const [acceptedFileList,setAcceptedFileList] = useState([])
  const history = useHistory()

  useEffect(()=>{
    if(Contrato && empresa && companies.includes(empresa) && Rut){
      setCompanySelected(empresa)
      setUploadStage(2)
      setEnablePrevButton(false)
    }
  },[companies,empresa,Rut,Contrato])

  const gotoPrev = () =>{
    if(uploadStage>1){
      setUploadStage(uploadStage-1)
    }
  }

  const gotoNext = () => {
    if(uploadStage===1){
      if(companySelected!==''){
        setUploadStage(2)
      }
    } else if(uploadStage===2){
      if(acceptedFileList.length>0){
        history.push(`${history.location.search}#/upload/total`)
        setUploadStage(3)
      }
    }
  }

  return (
    <Container>
      <Navigator/>
      {uploadStage === 1 && 
      <UploadStageSelectCompany
        Companies={companies}
        OnSelection={(company)=>{
          setCompanySelected(company)
        }}
        ProgressValue={33}
        ProgressText={"Paso 1 de 2"}
      />
      }
      {uploadStage === 2 &&
        <UploadStageSelectFiles
          filesToAccept={['LS']}
          documents={documents}
          acceptedFileList={acceptedFileList}
          setAcceptedFileList={setAcceptedFileList}
          ProgressValue={66}
          ProgressText={"Paso 2 de 2"}
          Rut={Rut}
        />
      }
      {uploadStage === 3 &&
        <UploadStageUploadFiles
          documents={documents}
          filesToUpload={acceptedFileList}
          company={companySelected}
          costCenters={costCenters}
          costCenter={centroCosto}
          Contrato={Contrato}
          ProgressValue={100}
          ProgressText={"Resultado"}
        />
      }
      {[1,2].includes(uploadStage) &&
      <div>
        <Row className="justify-content-left">
          <Col md={6} className="text-left text-md-left">
            <Button variant="link" onClick={()=>{
              history.push(enablePrevButton?'/':`/detallesPersonal/liquidaciones?Contrato=${Contrato}&Rut=${Rut}`)
            }}>Cancelar</Button>
          </Col>
          {enablePrevButton && 
          <Col md={6} className="text-right text-md-right">
            <Button variant="outline-secondary" onClick={gotoPrev}>Anterior</Button>&nbsp;
            <Button variant="outline-secondary" onClick={gotoNext} active>{uploadStage!==3?`Siguiente`:`Subir Documentos`}</Button>
          </Col>
          }
          {!enablePrevButton && 
          <Col md={6}>
            <Button variant="outline-secondary" onClick={gotoNext} active>{uploadStage!==3?`Siguiente`:`Subir Documentos`}</Button>
          </Col>
          }
        </Row>
      </div>
      }
      {[3].includes(uploadStage) &&
      <div>
        <Row className="justify-content-center">
          <Col md={12} className="text-center text-md-center">
            <Button variant="outline-secondary" onClick={()=>{
              setAcceptedFileList([])
              if(enablePrevButton){ 
                setUploadStage(1)
                setCompanySelected('')
                history.push('/upload/liquidaciones')
              } else {
                setUploadStage(2)
                history.push(`/upload/liquidaciones?Contrato=${Contrato}&empresa=${empresa}&centroCosto=${centroCosto}&Rut=${Rut}`)
              }
            }}>Realizar otra carga</Button>&nbsp;
            {enablePrevButton && 
            <Button variant="outline-secondary" onClick={()=>{
              history.push('/')
            }} active>Volver al Home</Button>
            }
            {!enablePrevButton && 
            <Button variant="outline-secondary" onClick={()=>{
              history.push(`/detallesPersonal/liquidaciones?Contrato=${Contrato}&Rut=${Rut}`)
            }} active>Volver a Liquidaciones</Button>
            }
          </Col>
        </Row>
      </div>
      }
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    companies:state.companies.companies,
    costCenters:state.costCenters.costCenters,
    documents:state.documents.documents,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentos)