import { API_ENDPOINT } from './APIEndpoint'
import { 
  SET_USERS,
  SET_USER_DETAILS
} from './actionTypes'
import { signOut } from './authActions'

export const fetchUsers = ({
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
  PageNumber,
  PageSize,
  history,
  OnFetchComplete,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/users`
  let params = []
  if(Empresas.length>0) params.push(`Empresas=${Empresas.join(',')}`)
  if(CentrosCosto.length>0) params.push(`CentrosCosto=${CentrosCosto.join(',')}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if(Regiones.length>0) params.push(`Regiones=${Regiones.join(',')}`)
  if(Sindicatos.length>0) params.push(`Sindicatos=${Sindicatos.join(',')}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if(Estados.length>0) params.push(`Estados=${Estados.join(',')}`)
  if((PageNumber || '')!=='') params.push(`PageNumber=${PageNumber}`)
  if((PageSize || '')!=='') params.push(`PageSize=${PageSize}`)
  query = `${query}?${params.join('&')}`
  try{
    let response = await fetch(
      query,{
        headers:{
          Authorization:token
        }
      }
    )
    if(response.status===200){
      let users = await response.json()
      dispatch({
        type:SET_USERS,
        employees:users.employees,
        totalPages:users.totalPages,
        TotalRecords: users.TotalRecords,
        StartRecord: users.StartRecord,
        currentPage:1,
      })
      OnFetchComplete()
    } else if(response.status===403){
      OnFetchComplete()
      localStorage.removeItem("docuus")
      dispatch(signOut())
      history.replace("/")
    }
  } catch(error){
    OnFetchComplete(error)
    console.log(error)
  }
}

export const fetchUsersExportUrl = ({
  Empresas,
  CentrosCosto,
  FechaIngreso,
  FechaIngreso2,
  Regiones,
  Sindicatos,
  Rut,
  Nombre,
  Estados,
}) => async (dispatch,getState) => {
  let state = getState()
  let { token } = state.auth
  let query = `${API_ENDPOINT}/users/export`
  let params = []
  if((Empresas || '')!=='') params.push(`Empresas=${Empresas}`)
  if((CentrosCosto || '')!=='') params.push(`CentrosCosto=${CentrosCosto}`)
  if((FechaIngreso || '')!=='') params.push(`FechaIngreso=${FechaIngreso}`)
  if((FechaIngreso2 || '')!=='') params.push(`FechaIngreso2=${FechaIngreso2}`)
  if((Regiones || '')!=='') params.push(`Regiones=${Regiones}`)
  if((Sindicatos || '')!=='') params.push(`Sindicatos=${Sindicatos}`)
  if((Rut || '')!=='') params.push(`Rut=${Rut}`)
  if((Nombre || '')!=='') params.push(`Nombre=${Nombre}`)
  if((Estados || '')!=='') params.push(`Estados=${Estados}`)
  query = `${query}?${params.join('&')}`
  try{
    let url = await (await fetch(query,{
      headers:{
        Authorization:token
      }
    })).text()
    window.open(url)
  } catch(error){
    console.log(error)
  }
}

export const fetchUserDetails = ({Rut,Contrato,OnFetchComplete}) => async (dispatch) => {
  let query = `${API_ENDPOINT}/user/contract?Rut=${Rut}&Contrato=${Contrato}`
  let details = await (await fetch(query)).json()
  dispatch({type:SET_USER_DETAILS,details})
  OnFetchComplete()
}

export const deleteUserAttachment = ({Contrato,Rut,s3Url,OnFetchComplete}) => async (dispatch) => {
  await fetch(`${API_ENDPOINT}/attachment/delete`,{
    method:'POST',
    headers: { "Content-Type":"application/json" },
    body: JSON.stringify({s3Url})
  })
  dispatch(fetchUserDetails({Contrato,Rut,OnFetchComplete}))
}