import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import ProtectedRoute from './ProtectedRoute'

const NonRutProtectedRoute = ({component, userType }) => {
    return (
    <Route render={props => {
        if(userType==="rut"){
            return <Redirect to="/" />
        } else {
            return <ProtectedRoute component={component} {...props}/>
        }
    }}/>
    )
}

const mapStateToProps = state =>{
    return {
        userType:state.auth.userType,
    };
};

export default connect(mapStateToProps)(NonRutProtectedRoute);