import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchReportsExportUrl } from '../../store/actions/reportsActions'

const ExportarReporte = ({
  fetchReportsExportUrl
}) =>{

  let query = new URLSearchParams(useLocation().search)
  
  const [Report] = useState(query.get("Report"))
  const [Empresas] = useState(query.get("Empresas"))
  const [CentrosCosto] = useState(query.get("CentrosCosto"))
  const [FechaIngreso] = useState(query.get("FechaIngreso"))
  const [FechaIngreso2] = useState(query.get("FechaIngreso2"))
  const [Regiones] = useState(query.get("Regiones"))
  const [Sindicatos] = useState(query.get("Sindicatos"))
  const [Rut] = useState(query.get("Rut"))
  const [Nombre] = useState(query.get("Nombre"))
  const [Estados] = useState(query.get("Estados"))

  useEffect(()=>{
    fetchReportsExportUrl({
      Report,
      Empresas,
      CentrosCosto,
      FechaIngreso,
      FechaIngreso2,
      Regiones,
      Sindicatos,
      Rut,
      Nombre,
      Estados,
    })
  },[Report,Empresas,CentrosCosto,FechaIngreso,FechaIngreso2,Regiones,Sindicatos,Rut,Nombre,Estados,fetchReportsExportUrl])

  return (
    <div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchReportsExportUrl
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ExportarReporte)