import React, { useState } from 'react'
import { Container, Row, Col, Button, FormControl, InputGroup } from 'react-bootstrap'
import styled from "styled-components";
import Modal from 'react-overlays/Modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Navigator from '../../components/Navigator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
  fetchDocuments,
  createDocument,
  editDocument,
  deleteDocument,
} from '../../store/actions/mantenedoresActions'

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

// we use some pseudo random coords so nested modals
// don't sit right on top of each other.
const PositionedModal = styled(Modal)`
  position: fixed;
  top: 10%;
  left: 25%;
  right: 25%;
  height:470px;
  z-index: 1040;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

const NuevoDocumentoModal = ({
  setNuevoDocumento,
  createDocument,
}) => {
  const renderBackdrop = (props) => <Backdrop {...props} />;
  const [Codigo,setCodigo] = useState('')
  const [Descripcion,setDescripcion] = useState('')
  const [Obligatorio,setObligatorio] = useState('')
  const SubmitChange = async()=>{
    createDocument({
      Codigo:Codigo,
      Descripcion:Descripcion,
      Obligatorio:Obligatorio,
    })
    setNuevoDocumento(false)
  }
  return (
    <PositionedModal
      show={true}
      onHide={() => setNuevoDocumento(false)}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <Container style={{paddingLeft:'6rem',paddingRight:'6rem'}}>
        <h4 id="modal-label">Nuevo Tipo de Documento</h4>
        <p style={{marginTop:'4rem',fontWeight:'bold'}}>Codigo</p>
        <FormControl
          placeholder="Ej A01"
          onChange={(e) => setCodigo(e.target.value)}
          value={Codigo}
        />
        <p style={{marginTop:'1rem',fontWeight:'bold'}}>Nombre de Documento</p>
        <FormControl
          placeholder="Nombre Documento"
          onChange={(e) => setDescripcion(e.target.value)}
          value={Descripcion}
        />
        <InputGroup className="edit-obligatorio">
          <InputGroup.Prepend>
            <InputGroup.Checkbox checked={Obligatorio} name="obligatorio" onChange={() => { setObligatorio(!Obligatorio); }}/>
            Obligatorio
          </InputGroup.Prepend>
        </InputGroup>
        <Row style={{display:'flex',justifyContent:'center', marginTop:'5rem'}}>
          <Button variant="outline-secondary" onClick={SubmitChange} active>Crear</Button>
        </Row>
        <Row style={{display:'flex',justifyContent:'center'}}>
          <Button variant="link" onClick={()=>{
            setNuevoDocumento(false)
          }}>Cancelar</Button>
        </Row>
      </Container>
    </PositionedModal>
  );
}

const EditDocumentoModal = ({
  documento,
  setDocumento,
  editDocument,
}) => {
  const renderBackdrop = (props) => <Backdrop {...props} />;
  const [Descripcion,setDescripcion] = useState(documento.Descripcion)
  const [Obligatorio,setObligatorio] = useState(documento.Obligatorio)
  const SubmitChange = async()=>{
    editDocument({
      Codigo:documento.Codigo,
      Descripcion:Descripcion,
      Obligatorio:Obligatorio,
    })
    setDocumento(null)
  }
  return (
    <PositionedModal
      show={documento!==null}
      onHide={() => setDocumento(null)}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <Container style={{paddingLeft:'6rem',paddingRight:'6rem'}}>
        <h4 id="modal-label">Editar Tipo de Documento</h4>
        <p style={{marginTop:'4rem',fontWeight:'bold'}}>Codigo</p>
        <FormControl
          placeholder="Ej A01"
          value={documento.Codigo}
          readOnly
        />
        <p style={{marginTop:'1rem',fontWeight:'bold'}}>Nombre de Documento</p>
        <FormControl
          placeholder="Nombre Documento"
          onChange={(e) => setDescripcion(e.target.value)}
          value={Descripcion}
        />
        <InputGroup className="edit-obligatorio">
          <InputGroup.Prepend>
            <InputGroup.Checkbox checked={Obligatorio} name="obligatorio" onChange={() => { setObligatorio(!Obligatorio); }}/>
            Obligatorio
          </InputGroup.Prepend>
        </InputGroup>
        <Row style={{display:'flex',justifyContent:'center', marginTop:'5rem'}}>
          <Button variant="outline-secondary" onClick={SubmitChange} active>Editar</Button>
        </Row>
        <Row style={{display:'flex',justifyContent:'center'}}>
          <Button variant="link" onClick={()=>{
            setDocumento(null)
          }}>Cancelar</Button>
        </Row>
      </Container>
    </PositionedModal>
  );
}

const DeleteDocumentoModal = ({
  documento,
  setDocumento,
  deleteDocument,
}) => {
  const renderBackdrop = (props) => <Backdrop {...props} />;
  const SubmitChange = async()=>{
    deleteDocument({
      Codigo:documento.Codigo
    })
    setDocumento(null)
  }
  return (
    <PositionedModal
      show={documento!==null}
      onHide={() => setDocumento(null)}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <Container style={{paddingLeft:'6rem',paddingRight:'6rem'}}>
        <h4 id="modal-label">Eliminar Tipo de Documento</h4>
        <p style={{marginTop:'4rem',fontWeight:'bold'}}>Codigo</p>
        <FormControl
          placeholder="Ej A01"
          value={documento.Codigo}
          readOnly
        />
        <p style={{marginTop:'1rem',fontWeight:'bold'}}>Nombre de Documento</p>
        <FormControl
          placeholder="Nombre Documento"
          value={documento.Descripcion}
          readOnly
        />
        <InputGroup className="edit-obligatorio">
          <InputGroup.Prepend>
            <InputGroup.Checkbox checked={documento.Obligatorio} name="obligatorio" readOnly/>
            Obligatorio
          </InputGroup.Prepend>
        </InputGroup>
        <Row style={{display:'flex',justifyContent:'center', marginTop:'5rem'}}>
          <Button variant="outline-secondary" onClick={SubmitChange} active>Eliminar</Button>
        </Row>
        <Row style={{display:'flex',justifyContent:'center'}}>
          <Button variant="link" onClick={()=>{
            setDocumento(null)
          }}>Cancelar</Button>
        </Row>
      </Container>
    </PositionedModal>
  );
}

const MantenedorDocumentos = ({
  documentos,
  fetchDocuments,
  createDocument,
  editDocument,
  deleteDocument,  
}) => {
  const [filtro,setFiltro] = useState('')
  const [documentoSeleccionado,setDocumentoSeleccionado] = useState(null)
  const [nuevoDocumento,setNuevoDocumento] = useState(false)
  const [documentoEliminar,setDocumentoEliminar] = useState(null)

  return (
    <Container>
      <Navigator/>
      <Row style={{marginTop:'4rem', verticalAlign:'center',display:'flex',alignItems:'center'}}>
        <Col md={10}>
          <h1>Mantenedor de tipo de Documentos</h1>
        </Col>
        <Col>
          <Button variant="outline-secondary" onClick={()=>{
            setNuevoDocumento(true)
          }} active>Crear nuevo</Button>
        </Col>
      </Row>
      <Row>
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Filtrar"
          onChange={(e) => setFiltro(e.target.value)}
          value={filtro}
        />
      </Row>
      {documentos.filter(documento=>{
        if(filtro==='') return true
        return (documento.Codigo.includes(filtro) || documento.Descripcion.includes(filtro))
      }).map((documento,index)=>{
        return (
        <Row key={index} className={"mantenedor-row"}>
          <Col xs={1}>
            <FontAwesomeIcon icon={faCheckCircle} color={documento.Obligatorio?"green":"gray"}/>
          </Col>
          <Col xs={1}>
            {documento.Codigo}
          </Col>
          <Col xs={8}>
            {documento.Descripcion}
          </Col>
          <Col xs={1}>
            <button className="fake-link" onClick={()=>{
              setDocumentoSeleccionado(documento)
            }}>
              Editar
            </button>
          </Col>
          <Col xs={1}>
            <button className="fake-link" onClick={()=>{
              setDocumentoEliminar(documento)
            }}>
              Eliminar
            </button>
          </Col>
        </Row>
        )
      })}
      {nuevoDocumento && 
        <NuevoDocumentoModal 
          createDocument={createDocument}
          setNuevoDocumento={setNuevoDocumento}
        />
      }
      {documentoSeleccionado!==null && <EditDocumentoModal
        documento={documentoSeleccionado}
        setDocumento={setDocumentoSeleccionado}
        editDocument={editDocument}
      />
      }
      {documentoEliminar!==null && <DeleteDocumentoModal
        documento={documentoEliminar}
        setDocumento={setDocumentoEliminar}
        deleteDocument={deleteDocument}
      />
      }
    </Container>
  )
}

const mapStateToProps = state => {
  return { 
    documentos:state.documents.documents
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchDocuments,
  createDocument,
  editDocument,
  deleteDocument,
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MantenedorDocumentos)