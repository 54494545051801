import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchUsersExportUrl } from '../store/actions/usersActions'

const ExportarPersonal = ({
  fetchUsersExportUrl
}) =>{

  let query = new URLSearchParams(useLocation().search)
  
  const [Empresas] = useState(query.get("Empresas"))
  const [CentrosCosto] = useState(query.get("CentrosCosto"))
  const [FechaIngreso] = useState(query.get("FechaIngreso"))
  const [FechaIngreso2] = useState(query.get("FechaIngreso2"))
  const [Regiones] = useState(query.get("Regiones"))
  const [Sindicatos] = useState(query.get("Sindicatos"))
  const [Rut] = useState(query.get("Rut"))
  const [Nombre] = useState(query.get("Nombre"))
  const [Estados] = useState(query.get("Estados"))

  useEffect(()=>{
    fetchUsersExportUrl({
      Empresas,
      CentrosCosto,
      FechaIngreso,
      FechaIngreso2,
      Regiones,
      Sindicatos,
      Rut,
      Nombre,
      Estados,
    })
  },[Empresas,CentrosCosto,FechaIngreso,FechaIngreso2,Regiones,Sindicatos,Rut,Nombre,Estados,fetchUsersExportUrl])

  return (
    <div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchUsersExportUrl
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ExportarPersonal)