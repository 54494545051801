import { Row, Button, Image } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { 
  signOut
} from '../store/actions/authActions'

const Navigator = ({
  nombre,
  apellido,
  signOut,
  userType,
  canUploadDocuments,
}) => {
  const history = useHistory()
  const location = useLocation();
  const OnSignOut = ()=>{
    localStorage.removeItem("docuus")
    signOut()
    history.replace("/")
  }

  return (
  <Row>
    <Image src="/logo192.png" style={{height:'40px', marginRight:'20px'}}/>
    {!["rut"].includes(userType) &&
      <Button variant="outline-secondary" onClick={()=>{
        history.push(`/`)
      }} {...(location.pathname==='/')?{active:true}:{}}>Administracion del personal</Button>
    }
    &nbsp;
    {!["rut"].includes(userType) && canUploadDocuments &&
      <Button variant="outline-secondary" onClick={()=>{
        history.push(`/upload/documentos`)
      }} {...(location.pathname==='/upload/documentos')?{active:true}:{}}>Subir Documentos</Button>
    }
    &nbsp;
    {!["rut"].includes(userType) && canUploadDocuments &&
      <Button variant="outline-secondary" onClick={()=>{
        history.push(`/upload/liquidaciones`)
      }} {...(location.pathname==='/upload/liquidaciones')?{active:true}:{}}>Subir Liquidaciones</Button>
    }
    &nbsp;
    {!["rut"].includes(userType) &&
      <Button variant="outline-secondary" onClick={()=>{
        history.push(`/reports/panel`)
      }} {...(location.pathname.startsWith('/reports/'))?{active:true}:{}}>Reportes</Button>
    }
    &nbsp;
    {["admin"].includes(userType) &&
    <Button variant="link" onClick={()=>{
      history.push(`/configuracion/panel`)
    }}>Configuracion</Button>
    }
    <div style={{display:'flex',position:'absolute',right:'40px',top:'5px'}}>
      <div style={{flexDirection:'column'}}>
        <p>{nombre} {apellido}</p>
        <Button variant="link" style={{padding:0,position:'absolute',right:'60px'}} onClick={OnSignOut}>Salir</Button>
      </div>
      <Image src="/blank-profile-picture.png" style={{height:'50px',borderRadius:'25px',marginLeft:'10px'}}/>
    </div>
  </Row>
  )
}
const mapStateToProps = state =>{
  return {
      signedIn:state.auth.signedIn,
      signedInChecked:state.auth.signedInChecked,
      userType:state.auth.userType,
      nombre:state.auth.nombre,
      apellido:state.auth.apellido,
      canUploadDocuments:state.auth.canUploadDocuments,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  signOut,
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Navigator)