import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, FormControl, InputGroup, Form, DropdownButton, Dropdown } from 'react-bootstrap'
import styled from "styled-components";
import Modal from 'react-overlays/Modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Navigator from '../../components/Navigator'
import { CustomToggle, CustomMenu } from '../../components/CustomToggle'

import {
  fetchConfiguracionUsuarios,
  createUsuario,
  deleteUsuario,
  editUsuario,
  fetchExecutiveUserConfiguration,
} from '../../store/actions/mantenedoresActions'

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

// we use some pseudo random coords so nested modals
// don't sit right on top of each other.
const PositionedModal = styled(Modal)`
  position: fixed;
  top: 10%;
  left: 25%;
  right: 25%;
  height:510px;
  z-index: 1040;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

const MiddlePositionedModal = styled(Modal)`
  position: fixed;
  top: 10%;
  left: 25%;
  right: 25%;
  height:440px;
  z-index: 1040;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

// we use some pseudo random coords so nested modals
// don't sit right on top of each other.
const SmallPositionedModal = styled(Modal)`
  position: fixed;
  top: 10%;
  left: 25%;
  right: 25%;
  height:220px;
  z-index: 1040;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

let userTypes = {
  "Rut":"rut",
  "Ejecutivo":"exec",
  "Cliente":"client",
  "Administrador":"admin"
}

const NuevoUsuarioModal = ({
  //actions
  HideModal,
  CreateUsuario,
  OnNext,
  //data
  Correo,setCorreo,
  Nombre,setNombre,
  Apellido,setApellido,
  TipoUsuario,setTipoUsuario,
  CanUploadDocuments,setCanUploadDocuments
}) => {
  const renderBackdrop = (props) => <Backdrop {...props} />;

  const SubmitChange = async()=>{
    CreateUsuario()
    HideModal()
  }
  let disabled = (Correo === '' || Nombre ==='' || Apellido === '')
  return (
    <PositionedModal
      show={true}
      onHide={HideModal}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <Container style={{paddingLeft:'6rem',paddingRight:'6rem'}}>
        <h4 id="modal-label">Nuevo Usuario</h4>
        <p style={{marginTop:'4rem',fontWeight:'bold'}}>Correo</p>
        <FormControl
          placeholder="Ej usuario@correo.com"
          onChange={(e) => setCorreo(e.target.value)}
          value={Correo}
        />
        <p style={{marginTop:'1rem',fontWeight:'bold'}}>Nombre y Apellido</p>
        <Row>
          <Col>
            <FormControl
              placeholder="Nombre"
              onChange={(e) => setNombre(e.target.value)}
              value={Nombre}
          />
          </Col>
          <Col>
            <FormControl
              placeholder="Apellido"
              onChange={(e) => setApellido(e.target.value)}
              value={Apellido}
          />
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formActivo">
            <Form.Label><strong>Tipo Usuario</strong></Form.Label>
            <DropdownButton id="dropdown-basic-button" title={TipoUsuario} variant="outline-secondary">
              <Dropdown.Item onClick={()=>{setTipoUsuario("Ejecutivo")}}>Ejecutivo</Dropdown.Item>
              <Dropdown.Item onClick={()=>{setTipoUsuario("Cliente");setCanUploadDocuments(false)}}>Cliente</Dropdown.Item>
              <Dropdown.Item onClick={()=>{setTipoUsuario("Administrador")}}>Administrador</Dropdown.Item>
            </DropdownButton>
          </Form.Group>
        </Row>
        {['Ejecutivo'].includes(TipoUsuario) &&
          <Row>
            <InputGroup className="edit-canuploaddocuments">
              <InputGroup.Prepend>
                <InputGroup.Checkbox checked={CanUploadDocuments} name="canuploaddocuments" onChange={() => { 
                  setCanUploadDocuments(!CanUploadDocuments)
                }}/>
                Puede Subir Documentos
              </InputGroup.Prepend>
            </InputGroup>
          </Row>
        }
        {['Administrador'].includes(TipoUsuario) &&
        <Row style={{display:'flex',justifyContent:'center', marginTop:'5rem'}}>
          <Button variant="outline-secondary" onClick={SubmitChange} active disabled={disabled}>Crear</Button>
        </Row>
        }
        {['Ejecutivo','Cliente'].includes(TipoUsuario) &&
        <Row style={{display:'flex',justifyContent:'center', marginTop:'5rem'}}>
          <Button variant="outline-secondary" onClick={OnNext} active disabled={disabled}>Siguiente</Button>
        </Row>
        }
        <Row style={{display:'flex',justifyContent:'center'}}>
          <Button variant="link" onClick={HideModal}>Cancelar</Button>
        </Row>
      </Container>
    </PositionedModal>
  );
}

const NuevoUsuarioEmpresaModal = ({
  //actions
  HideModal,
  OnNext,
  //for selection
  companies,
  //data
  Empresas,setEmpresas,
}) => {
  const renderBackdrop = (props) => <Backdrop {...props} />;

  let disabled = (Empresas.length === 0)
  let toggleEmpresa = (empresaToToggle)=>{
    let empresaIndex = Empresas.indexOf(empresaToToggle)
    if(empresaIndex===-1){
      setEmpresas([...Empresas,empresaToToggle])
    } else {
      setEmpresas([...Empresas].filter((_,index)=>index!==empresaIndex))
    }
  }
  let EmpresasTitle = ''
  if(Empresas.length===0) { EmpresasTitle = 'Seleccione Empresas' }
  if(Empresas.length===1) { EmpresasTitle = Empresas[0] }
  if(Empresas.length > 1) { EmpresasTitle = `${Empresas.length} Empresas` }

  return (
    <SmallPositionedModal
      show={true}
      onHide={HideModal}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <Container style={{paddingLeft:'6rem',paddingRight:'6rem'}}>
        <h4 id="modal-label">Seleccionar Empresa</h4>
        <Row>
          <Form.Group controlId="formActivo">
            <Form.Label><strong>Empresas</strong></Form.Label>
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                {EmpresasTitle}
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                {
                  Empresas.length<companies.length &&
                  <Dropdown.Item onClick={() => { setEmpresas(companies) }}>Todas</Dropdown.Item>  
                }
                {
                  Empresas.length === companies.length &&
                  <Dropdown.Item onClick={() => { setEmpresas([]) }}>Ninguna</Dropdown.Item>
                }
                {companies.map((empresa,index)=>
                  <Dropdown.Item key={index} onClick={() => { toggleEmpresa(empresa) }} active={(Empresas.indexOf(empresa)!==-1)}>{empresa}</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Row>
        <Row style={{display:'flex',justifyContent:'center', marginTop:'1rem'}}>
          <Button variant="outline-secondary" onClick={OnNext} active disabled={disabled}>Siguiente</Button>
        </Row>
        <Row style={{display:'flex',justifyContent:'center'}}>
          <Button variant="link" onClick={HideModal}>Cancelar</Button>
        </Row>
      </Container>
    </SmallPositionedModal>
  );
}

const NuevoUsuarioRegionesModal = ({
  //actions
  HideModal,
  OnNext,
  //for selection
  regions,
  //data
  Regiones,setRegiones,
}) => {
  const renderBackdrop = (props) => <Backdrop {...props} />;

  let disabled = (Regiones.length === 0)
  let toggleRegion = (regionToToggle)=>{
    let regionIndex = Regiones.indexOf(regionToToggle)
    if(regionIndex===-1){
      setRegiones([...Regiones,regionToToggle])
    } else {
      setRegiones([...Regiones].filter((_,index)=>index!==regionIndex))
    }
  }
  let RegionesTitle = ''
  if(Regiones.length===0) { RegionesTitle = 'Seleccione Region' }
  if(Regiones.length===1) { RegionesTitle = regions.find(region => region.id === Regiones[0])?.nombre || `Region ${Regiones[0]}` }
  if(Regiones.length > 1) { RegionesTitle = `${Regiones.length} Regiones` }

  return (
    <SmallPositionedModal
      show={true}
      onHide={HideModal}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <Container style={{paddingLeft:'6rem',paddingRight:'6rem'}}>
        <h4 id="modal-label">Seleccionar Regiones</h4>
        <Row>
          <Form.Group as={Col} controlId="formActivo">
            <Form.Label><strong>Regiones</strong></Form.Label>
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                {RegionesTitle}
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                {
                  Regiones.length<regions.length &&
                  <Dropdown.Item onClick={() => { setRegiones(regions.map(({id})=>id)) }}>Todas</Dropdown.Item>  
                }
                {
                  Regiones.length === regions.length &&
                  <Dropdown.Item onClick={() => { setRegiones([]) }}>Ninguna</Dropdown.Item>
                }
                {regions.map((region)=>{
                  if(Regiones.indexOf(region.id)===-1){
                    return <Dropdown.Item key={region.id} onClick={() => { toggleRegion(region.id) }}>{region.nombre}</Dropdown.Item>
                  } else {
                    return <Dropdown.Item key={region.id} onClick={() => { toggleRegion(region.id) }} active>{region.nombre}</Dropdown.Item>
                  }
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Row>
        <Row style={{display:'flex',justifyContent:'center', marginTop:'1rem'}}>
          <Button variant="outline-secondary" onClick={OnNext} active disabled={disabled}>Siguiente</Button>
        </Row>
        <Row style={{display:'flex',justifyContent:'center'}}>
          <Button variant="link" onClick={HideModal}>Cancelar</Button>
        </Row>
      </Container>
    </SmallPositionedModal>
  );
}

const NuevoUsuarioCentrosCostoModal = ({
  //actions
  HideModal,
  CreateUsuario,
  //for selection
  costCenters,
  //data
  CentrosCosto,setCentrosCosto,
}) => {
  const SubmitChange = async () => {
    CreateUsuario()
    HideModal()
  }
  const renderBackdrop = (props) => <Backdrop {...props} />;

  let disabled = (CentrosCosto.length === 0)
  let toggleCentrosDeCostos = (costCenterToToggle)=>{
    let costCenterIndex = CentrosCosto.indexOf(costCenterToToggle)
    if(costCenterIndex===-1){
      setCentrosCosto([...CentrosCosto,costCenterToToggle])
    } else {
      setCentrosCosto([...CentrosCosto].filter((_,index)=>index!==costCenterIndex))
    }
  }
  let CentrosCostoTitle = ''
  if(CentrosCosto.length===0) { CentrosCostoTitle = 'Seleccione Centros de Costo' }
  if(CentrosCosto.length===1) { CentrosCostoTitle = costCenters.find(costCenter => costCenter.item === CentrosCosto[0])?.nombre || `Centro Costo ${CentrosCosto[0]}` }
  if(CentrosCosto.length > 1) { CentrosCostoTitle = `${CentrosCosto.length} Centros de Costo` }

  return (
    <SmallPositionedModal
      show={true}
      onHide={HideModal}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <Container style={{paddingLeft:'6rem',paddingRight:'6rem'}}>
        <h4 id="modal-label">Seleccionar Centros de Costo</h4>
        <Row>
          <Form.Group as={Col} controlId="formActivo">
            <Form.Label><strong>Centros de Costo</strong></Form.Label>
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                {CentrosCostoTitle}
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                {
                  CentrosCosto.length < costCenters.length &&
                  <Dropdown.Item onClick={() => { setCentrosCosto(costCenters.map(({item})=>item)) }}>Todas</Dropdown.Item>  
                }
                {
                  CentrosCosto.length === costCenters.length &&
                  <Dropdown.Item onClick={() => { setCentrosCosto([]) }}>Ninguna</Dropdown.Item>
                }
                {costCenters.map((costCenter,index)=>{
                  if(CentrosCosto.indexOf(costCenter.item)===-1){
                    return <Dropdown.Item key={index} onClick={() => { toggleCentrosDeCostos(costCenter.item) }}>{costCenter.nombre}</Dropdown.Item>
                  } else {
                    return <Dropdown.Item key={index} onClick={() => { toggleCentrosDeCostos(costCenter.item) }} active>{costCenter.nombre}</Dropdown.Item>
                  }
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Row>
        <Row style={{display:'flex',justifyContent:'center', marginTop:'1rem'}}>
          <Button variant="outline-secondary" onClick={SubmitChange} active disabled={disabled}>Crear</Button>
        </Row>
        <Row style={{display:'flex',justifyContent:'center'}}>
          <Button variant="link" onClick={HideModal}>Cancelar</Button>
        </Row>
      </Container>
    </SmallPositionedModal>
  );
}

const DeleteUsuarioModal = ({
  //actions
  HideModal,

  user,
  deleteUsuario
}) => {
  const SubmitChange = async () => {
    deleteUsuario()
    HideModal()
  }
  const renderBackdrop = (props) => <Backdrop {...props} />;

  return (
    <SmallPositionedModal
      show={true}
      onHide={HideModal}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <Container style={{paddingLeft:'6rem',paddingRight:'6rem'}}>
        <h4 id="modal-label">Eliminacion de Cuenta</h4>
        <Row>
          <Form.Group as={Col} controlId="formActivo">
            <Form.Label>
              <strong>Esta seguro de eliminar la cuenta</strong>
              <br/>
              {user.username}
              <br/>
              {user.nombre} {user.apellido}
              </Form.Label>
          </Form.Group>
        </Row>
        <Row style={{display:'flex',justifyContent:'center', marginTop:'1rem'}}>
          <Button variant="outline-secondary" onClick={SubmitChange} active>Eliminar</Button>
        </Row>
        <Row style={{display:'flex',justifyContent:'center'}}>
          <Button variant="link" onClick={HideModal}>Cancelar</Button>
        </Row>
      </Container>
    </SmallPositionedModal>
  );
}

const EditUsuarioModal = ({
  //actions
  HideModal,

  companies,
  regions,
  costCenters,

  fetchExecutiveUserConfiguration,

  user,
  editUsuario
}) => {
  const SubmitChange = async () => {
    editUsuario({
      username:user.username,
      nombre:nombreUsuario,
      apellido:apellidoUsuario,
      password:passwordUsuario,
      empresas:empresasUsuario,
      regiones:regionesUsuario,
      centrosCosto:centrosCostoUsuario,
      CanUploadDocuments:canUploadDocuments,
    })
    HideModal()
  }

  const renderBackdrop = (props) => <Backdrop {...props} />;

  const [nombreUsuario,setNombreUsuario] = useState(user.nombre)
  const [apellidoUsuario,setApellidoUsuario] = useState(user.apellido)
  const [createNewPassword,setCreateNewPassword] = useState(false)
  const [passwordUsuario,setPasswordUsuario] = useState('')
  const [canUploadDocuments,setCanUploadDocuments] = useState(user.canUploadDocuments)

  const [empresasUsuario,setEmpresasUsuario] = useState([])
  const [regionesUsuario,setRegionesUsuario] = useState([])
  const [centrosCostoUsuario,setCentrosCostoUsuario] = useState([])

  const [empresasChanged,setEmpresasChanged] = useState(false)
  const [regionesChanged,setRegionesChanged] = useState(false)
  const [centrosCostoChanged,setCentrosCostoChanged] = useState(false)

  useEffect(()=>{
    if(["exec","client"].includes(user.userType)){
      fetchExecutiveUserConfiguration({UserName:user.username,OnFetchComplete:(userData)=>{
        setEmpresasUsuario([...userData.empresas])
        setRegionesUsuario([...userData.regiones])
        setCentrosCostoUsuario([...userData.centrosCosto])
      }})
    }
  },[setEmpresasUsuario,setRegionesUsuario,setCentrosCostoUsuario,fetchExecutiveUserConfiguration,user])

  let toggleEmpresa = (empresaToToggle)=>{
    setEmpresasChanged(true)
    let empresaIndex = empresasUsuario.indexOf(empresaToToggle)
    if(empresaIndex===-1){
      setEmpresasUsuario([...empresasUsuario,empresaToToggle])
    } else {
      setEmpresasUsuario([...empresasUsuario].filter((_,index)=>index!==empresaIndex))
    }
  }

  let EmpresasTitle = ''
  if(empresasUsuario.length===0) { EmpresasTitle = 'Seleccione Empresas' }
  if(empresasUsuario.length===1) { EmpresasTitle = empresasUsuario[0] }
  if(empresasUsuario.length > 1) { EmpresasTitle = `${empresasUsuario.length} Empresas` }

  let toggleRegion = (regionToToggle)=>{
    setRegionesChanged(true)
    let regionIndex = regionesUsuario.indexOf(regionToToggle)
    if(regionIndex===-1){
      setRegionesUsuario([...regionesUsuario,regionToToggle])
    } else {
      setRegionesUsuario([...regionesUsuario].filter((_,index)=>index!==regionIndex))
    }
  }

  let RegionesTitle = ''
  if(regionesUsuario.length===0) { RegionesTitle = 'Seleccione Region' }
  if(regionesUsuario.length===1) { RegionesTitle = regions.find(region => region.id === regionesUsuario[0])?.nombre || `Region ${regionesUsuario[0]}` }
  if(regionesUsuario.length > 1) { RegionesTitle = `${regionesUsuario.length} Regiones` }

  let toggleCentrosDeCostos = (costCenterToToggle)=>{
    setCentrosCostoChanged(true)
    let costCenterIndex = centrosCostoUsuario.indexOf(costCenterToToggle)
    if(costCenterIndex===-1){
      setCentrosCostoUsuario([...centrosCostoUsuario,costCenterToToggle])
    } else {
      setCentrosCostoUsuario([...centrosCostoUsuario].filter((_,index)=>index!==costCenterIndex))
    }
  }
  let CentrosCostoTitle = ''
  if(centrosCostoUsuario.length===0) { CentrosCostoTitle = 'Seleccione Centros de Costo' }
  if(centrosCostoUsuario.length===1) { CentrosCostoTitle = costCenters.find(costCenter => costCenter.item === centrosCostoUsuario[0])?.nombre || `Centro Costo ${centrosCostoUsuario[0]}` }
  if(centrosCostoUsuario.length > 1) { CentrosCostoTitle = `${centrosCostoUsuario.length} Centros de Costo` }

  let editionEnabled = (
    nombreUsuario !== '' && 
    apellidoUsuario !== '' && 
    (apellidoUsuario !== user.apellido || nombreUsuario !== user.nombre)
  )
  editionEnabled |= (createNewPassword && passwordUsuario!=='')
  editionEnabled |= (empresasChanged || regionesChanged || centrosCostoChanged)
  if(["exec"].includes(user.userType)){
    editionEnabled |= (user.canUploadDocuments !== canUploadDocuments)
  }

  return (
    <MiddlePositionedModal
      show={true}
      onHide={HideModal}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
    >
      <Container style={{paddingLeft:'6rem',paddingRight:'6rem'}}>
        <h4 id="modal-label">Edicion de Cuenta</h4>
        <Row>
          <Form.Label className="mx-3"> 
            {user.username}
          </Form.Label>
        </Row>
        <p style={{marginTop:'1rem',fontWeight:'bold'}}>Nombre y Apellido</p>
        <Row>
          <Col>
            <FormControl
              placeholder="Nombre"
              onChange={(e) => setNombreUsuario(e.target.value)}
              value={nombreUsuario}
              disabled={user.userType==="rut"}
          />
          </Col>
          <Col>
            <FormControl
              placeholder="Apellido"
              onChange={(e) => setApellidoUsuario(e.target.value)}
              value={apellidoUsuario}
              disabled={user.userType==="rut"}
          />
          </Col>
        </Row>
        <Row>
          <InputGroup className="edit-newpassword">
            <InputGroup.Prepend>
              <InputGroup.Checkbox checked={createNewPassword} name="newpassword" onChange={() => { 
                setCreateNewPassword(!createNewPassword); 
                setPasswordUsuario('')
              }}/>
              Remplazar Contraseña
            </InputGroup.Prepend>
          </InputGroup>
        </Row>
        <Row>
          <FormControl
            autoFocus
            className="mx-3 w-auto"
            type="password" placeholder="Nueva Contraseña" 
            onChange={(e) => setPasswordUsuario(e.target.value)}
            value={createNewPassword?passwordUsuario:''}
            disabled={!createNewPassword}
          />
        </Row>
        {user.userType==="exec" && 
        <Row>
          <InputGroup className="edit-canuploaddocuments">
            <InputGroup.Prepend>
              <InputGroup.Checkbox checked={canUploadDocuments} name="canuploaddocuments" onChange={() => { 
                setCanUploadDocuments(!canUploadDocuments)
              }}/>
              Puede Subir Documentos
            </InputGroup.Prepend>
          </InputGroup>
        </Row>
        }
        {["exec","client"].includes(user.userType) &&
        <Row>
          <Col xs={4}>
            <Form.Group controlId="formActivo">
              <Form.Label><strong>Empresas</strong></Form.Label>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  {EmpresasTitle}
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu}>
                  {
                    empresasUsuario.length<companies.length &&
                    <Dropdown.Item onClick={() => { setEmpresasChanged(true);setEmpresasUsuario(companies) }}>Todas</Dropdown.Item>  
                  }
                  {
                    empresasUsuario.length === companies.length &&
                    <Dropdown.Item onClick={() => { setEmpresasChanged(true);setEmpresasUsuario([]) }}>Ninguna</Dropdown.Item>
                  }
                  {companies.map((empresa,index)=>
                    <Dropdown.Item key={index} onClick={() => { toggleEmpresa(empresa) }} active={(empresasUsuario.indexOf(empresa)!==-1)}>{empresa}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group controlId="formActivo">
              <Form.Label><strong>Regiones</strong></Form.Label>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  {RegionesTitle}
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu}>
                  {
                    regionesUsuario.length<regions.length &&
                    <Dropdown.Item onClick={() => { setRegionesChanged(true);setRegionesUsuario(regions.map(({id})=>id)) }}>Todas</Dropdown.Item>  
                  }
                  {
                    regionesUsuario.length === regions.length &&
                    <Dropdown.Item onClick={() => { setRegionesChanged(true);setRegionesUsuario([]) }}>Ninguna</Dropdown.Item>
                  }
                  {regions.map((region)=>
                    <Dropdown.Item key={region.id} onClick={() => { toggleRegion(region.id) }} active={(regionesUsuario.indexOf(region.id)!==-1)}>{region.nombre}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group controlId="formActivo">
              <Form.Label><strong>Centros de Costo</strong></Form.Label>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  {CentrosCostoTitle}
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu}>
                  {
                    centrosCostoUsuario.length < costCenters.length &&
                    <Dropdown.Item onClick={() => { setCentrosCostoChanged(true);setCentrosCostoUsuario(costCenters.map(({item})=>item)) }}>Todas</Dropdown.Item>  
                  }
                  {
                    centrosCostoUsuario.length === costCenters.length &&
                    <Dropdown.Item onClick={() => { setCentrosCostoChanged(true);setCentrosCostoUsuario([]) }}>Ninguna</Dropdown.Item>
                  }
                  {costCenters.map((costCenter,index)=>
                    <Dropdown.Item key={index} onClick={() => { toggleCentrosDeCostos(costCenter.item) }} active={(centrosCostoUsuario.indexOf(costCenter.item)!==-1)}>{costCenter.nombre}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </Col>
        </Row>
        }
        <Row style={{display:'flex',justifyContent:'center', marginTop:'1rem'}}>
          <Button variant="outline-secondary" onClick={SubmitChange} active disabled={!editionEnabled}>Editar</Button>
        </Row>
        <Row style={{display:'flex',justifyContent:'center'}}>
          <Button variant="link" onClick={HideModal}>Cancelar</Button>
        </Row>
      </Container>
    </MiddlePositionedModal>
  );
}

const MantenedorUsuarios = ({
  fetchConfiguracionUsuarios,
  fetchExecutiveUserConfiguration,
  createUsuario,
  deleteUsuario,
  editUsuario,

  usuarios,
  companies,
  regions,
  costCenters,
}) => {
  const [filtro,setFiltro] = useState('')
  const [nuevoUsuario,setNuevoUsuario] = useState(false)
  const [nuevoUsuarioEmpresas,setNuevoUsuarioEmpresas] = useState(false)
  const [nuevoUsuarioRegiones,setNuevoUsuarioRegiones] = useState(false)
  const [nuevoUsuarioCentrosCosto,setNuevoUsuarioCentrosCosto] = useState(false)

  //new account
  const [Correo,setCorreo] = useState('')
  const [Nombre,setNombre] = useState('')
  const [Apellido,setApellido] = useState('')
  const [TipoUsuario,setTipoUsuario] = useState('Ejecutivo')
  const [Empresas,setEmpresas] = useState('')
  const [Regiones,setRegiones] = useState([])
  const [CentrosCosto,setCentrosCosto] = useState([])
  const [CanUploadDocuments,setCanUploadDocuments] = useState(false)

  //edit account
  const [userToEdit,setUserToEdit] = useState(null)
  //delete account
  const [userToDelete,setUserToDelete] = useState(null)

  /*
  const ResetNewAccountData = () => {
    setCorreo('')
    setNombre('')
    setApellido('')
    setTipoUsuario('Ejecutivo')
    setEmpresas([])
    setRegiones([])
    setCentrosCosto([])
  }*/

  const CreateUsuario = ()=>{
    if(['Ejecutivo','Cliente'].includes(TipoUsuario)){
      createUsuario({
        Correo,
        Nombre,
        Apellido,
        TipoUsuario:userTypes[TipoUsuario],
        Empresas,
        Regiones,
        CentrosCosto,
        CanUploadDocuments
      })
    } else if(TipoUsuario==='Administrador'){
      createUsuario({
        Correo,
        Nombre,
        Apellido,
        TipoUsuario:userTypes[TipoUsuario],
        CanUploadDocuments:true
      })
    }
  }

  const DeleteUsuario = ()=>{
    deleteUsuario({
      Correo:userToDelete.username
    })
  }

  useEffect(()=>{
    fetchConfiguracionUsuarios()
  },[fetchConfiguracionUsuarios])

  return (
    <Container>
      <Navigator/>
      <Row style={{marginTop:'4rem', verticalAlign:'center',display:'flex',alignItems:'center'}}>
        <Col md={10}>
          <h1>Usuarios</h1>
        </Col>
        <Col>
          <Button variant="outline-secondary" onClick={()=>{
            //ResetNewAccountData()
            setNuevoUsuario(true)
          }} active>Crear nuevo</Button>
        </Col>
      </Row>
      <Row>
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Filtrar"
          onChange={(e) => setFiltro(e.target.value)}
          value={filtro}
        />
      </Row>
      {usuarios.filter(usuario=>{
        if(filtro==='') return true
        return (
          usuario.username.includes(filtro) ||
          usuario.nombre.includes(filtro) ||
          usuario.apellido.includes(filtro) || 
          `${usuario.nombre} ${usuario.apellido}`.includes(filtro)
        )
      }).map((usuario,index)=>{
        return (
        <Row key={index} className={"mantenedor-row"}>
          <Col xs={3}>
            {usuario.username}
          </Col>
          <Col xs={7}>
            {usuario.nombre} {usuario.apellido}
          </Col>
          <Col xs={1}>
            <button className="fake-link" onClick={()=>{
              setUserToEdit(usuario)
            }}>
              Editar
            </button>
          </Col>
          <Col xs={1}>
            <button className="fake-link" onClick={()=>{
              setUserToDelete(usuario)
            }}>
              Eliminar
            </button>
          </Col>
        </Row>
        )
      })}      
      {nuevoUsuario && 
        <NuevoUsuarioModal 
          HideModal={()=>{setNuevoUsuario(false)}}
          CreateUsuario={CreateUsuario}
          OnNext={()=>{
            setNuevoUsuario(false)
            setNuevoUsuarioEmpresas(true)
          }}

          Correo={Correo}
          setCorreo={setCorreo}
          Nombre={Nombre}
          setNombre={setNombre}
          Apellido={Apellido}
          setApellido={setApellido}
          TipoUsuario={TipoUsuario}
          setTipoUsuario={setTipoUsuario}
          CanUploadDocuments={CanUploadDocuments}
          setCanUploadDocuments={setCanUploadDocuments}
        />
      }
      {nuevoUsuarioEmpresas &&
        <NuevoUsuarioEmpresaModal
          HideModal={()=>{setNuevoUsuarioEmpresas(false)}}
          OnNext={()=>{
            setNuevoUsuarioEmpresas(false)
            setNuevoUsuarioRegiones(true)
          }}

          companies={companies}
          Empresas={Empresas}
          setEmpresas={setEmpresas}
        />
      }
      {nuevoUsuarioRegiones &&
        <NuevoUsuarioRegionesModal
          HideModal={()=>{setNuevoUsuarioRegiones(false)}}
          OnNext={()=>{
            setNuevoUsuarioRegiones(false)
            setNuevoUsuarioCentrosCosto(true)
          }}

          regions={regions}
          Regiones={Regiones}
          setRegiones={setRegiones}
        />
      }
      {nuevoUsuarioCentrosCosto &&
        <NuevoUsuarioCentrosCostoModal
          HideModal={()=>{setNuevoUsuarioCentrosCosto(false)}}
          CreateUsuario={CreateUsuario}

          costCenters={costCenters}
          CentrosCosto={CentrosCosto}
          setCentrosCosto={setCentrosCosto}
        />
      }
      {userToDelete!==null && 
        <DeleteUsuarioModal
          HideModal={()=>{setUserToDelete(null)}}

          user={userToDelete}
          deleteUsuario={DeleteUsuario}
        />
      }
      {userToEdit!==null &&
        <EditUsuarioModal
          HideModal={()=>{setUserToEdit(null)}}

          companies={companies}
          regions={regions}
          costCenters={costCenters}

          fetchExecutiveUserConfiguration={fetchExecutiveUserConfiguration}

          user={userToEdit}
          editUsuario={editUsuario}
        />
      }
    </Container>
  )
}

const mapStateToProps = state => {
  return { 
    usuarios:state.configuracionUsuarios.usuarios,
    companies:state.companies.companies,
    regions:state.regions.regions,
    costCenters:state.costCenters.costCenters,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchConfiguracionUsuarios,
  createUsuario,
  deleteUsuario,
  editUsuario,
  fetchExecutiveUserConfiguration,
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MantenedorUsuarios)