import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form, Image } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import {
  signOut,
  resetSignInCredentials
} from '../store/actions/authActions'
import { useCallback } from 'react'

const ResetPassword = ({
  signOut,
  resetSignInCredentials,
  UserName,
}) => {
  const [Pass,setPass] = useState('')
  const [Pass2,setPass2] = useState('')
  const [Pass3,setPass3] = useState('')
  const [changePasswordError,setChangePasswordError] = useState('')
  const history = useHistory()

  const onResetPasswordSuccess = () => {
    signOut()
    history.push('/')
  }

  const onResetPasswordError = (errorMessage) => {
    setChangePasswordError(errorMessage)
  }

  const doResetPassword = () => {
    if(Pass2!==Pass3){
      setChangePasswordError('Nuevas contraseñas no son iguales')
    } else {
      resetSignInCredentials({
        UserName,
        OldPass:Pass,
        NewPass:Pass2,
        OnSuccess:onResetPasswordSuccess,
        OnError:onResetPasswordError,
      })
    }
  }

  const doSwitchAccount = useCallback(() => {
    signOut()
    history.push('/')
  },[signOut,history])

  useEffect(()=>{
    if(UserName===''){
      doSwitchAccount()
    }
  },[UserName,doSwitchAccount])

  let disabled = (Pass === '' || Pass2 === '' || Pass3 === '')

  return (
    <Container>
      <Row className="justify-content-sm-center">
        <Image src="/logo512.png" style={{height:'250px'}}/>
      </Row>
      <Row className="justify-content-sm-center"><h1>Cambio de contraseña</h1></Row>
      <Row className="justify-content-sm-center">
        <Col sm={6}>
          <Form.Group as={Col} controlId="formUserName" style={{marginBottom:'0'}}>
            <Form.Label style={{marginBottom:'0'}}><strong>Usuario:</strong> {UserName}</Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-sm-center">
        <Col sm={6}>
          <Form.Group as={Col} controlId="formPass" style={{marginBottom:'0'}}>
            <Form.Label style={{marginBottom:'0'}}><strong>Contraseña</strong></Form.Label>
            <Form.Control type="password" placeholder="contraseña" value={Pass} onChange={(e)=>{
              setChangePasswordError('');
              setPass(e.target.value)
            }}/>
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-sm-center">
        <Col sm={6}>
          <Form.Group as={Col} controlId="formPass2" style={{marginBottom:'0'}}>
            <Form.Label style={{marginBottom:'0'}}><strong>Nueva contraseña</strong></Form.Label>
            <Form.Control type="password" placeholder="contraseña" value={Pass2} onChange={(e)=>{
              setChangePasswordError('')
              setPass2(e.target.value)
            }}/>
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-sm-center">
        <Col sm={6}>
          <Form.Group as={Col} controlId="formPass3" style={{marginBottom:'0'}}>
            <Form.Label style={{marginBottom:'0'}}><strong>Repetir nueva contraseña</strong></Form.Label>
            <Form.Control type="password" placeholder="contraseña" value={Pass3} onChange={(e)=>{
              setChangePasswordError('')
              setPass3(e.target.value)
            }}/>
          </Form.Group>
        </Col>
      </Row>
      {changePasswordError!=='' &&
        <Row className="justify-content-sm-center">
          <span style={{
            "backgroundColor":"indianred",
            "color":"white",
            "marginTop":"1rem",
            "paddingLeft":"1rem",
            "paddingRight":"1rem"
          }}>Error: {changePasswordError}</span>
        </Row>
      }
      <Row sm={4} className="justify-content-sm-center" style={{marginTop:'1rem'}}>
        <Button variant="outline-secondary" onClick={doResetPassword} disabled={disabled}>Cambiar Contraseña</Button>
      </Row>
      <Row sm={9} className="justify-content-sm-center" style={{marginTop:'1rem'}}>
        <Button variant="link" onClick={doSwitchAccount}>No es mi cuenta</Button>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    UserName:state.auth.UserName// || 's@4ptech.com',
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  signOut,
  resetSignInCredentials,
},dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)